import React from "react";
import { combineProviders } from "react-combine-providers";
import { ConfigurationProvider } from "./contexts/Configuration";
import { SearchProvider } from "./contexts/Search";

const providers = new combineProviders();
providers.push(ConfigurationProvider);
providers.push(SearchProvider);

const MasterProvider = providers.master();

const Store = ({ children }) => {
  return <MasterProvider>{children}</MasterProvider>;
};

export default Store;
