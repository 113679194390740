import React, { useContext } from "react";
import DialogBox from "../CommonComponent/DialogBox";
import DynamicHtml from "../CommonComponent/DynamicHtml";
import { Card } from "@mui/material";
import { ConfigurationContext } from "../../../contexts/Configuration";
import { SearchContext } from "../../../contexts/Search";

const PrescriberCustomNoteDialog = ({
  isOpen,
  onClose,
  noteDetails,
  resultname,
}) => {
  const { appliedConfiguration } = useContext(ConfigurationContext);
  const { benefitType } = useContext(SearchContext);
  const benefitTypeId = benefitType == "Pharmacy" ? 2 : 1;
  const header = appliedConfiguration.coverageFinderPrescriberNoteHeaders.filter(
    (obj) => obj.benefitTypeId === benefitTypeId
  )[0];
  return (
    <DialogBox isOpen={isOpen} onClose={onClose}>
      {
        <div className="norstella-cf-prescriberCustomNoteDialog" part="cfDivPrescriberCustomNoteDialog">
          <DynamicHtml
            input={header != null ? header.header : ""}
            resultname={resultname}
            className="norstella-cf-prescriberCustomNoteHeader"
          />
          <Card className="norstella-cf-prescriberNoteCardContent">
            <div className="norstella-cf-notedetails" dangerouslySetInnerHTML={{ __html: noteDetails }}></div>
          </Card>
        </div>
      }
    </DialogBox>
  );
};

export default PrescriberCustomNoteDialog;
