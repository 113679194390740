import React, { useState } from "react";

export const ConfigurationContext = React.createContext();


export const ConfigurationProvider = ({ children }) => {

  const [appliedConfiguration, setAppliedConfiguration] = useState({});


  return (
    <ConfigurationContext.Provider value={{appliedConfiguration, setAppliedConfiguration}}>
    {children}
  </ConfigurationContext.Provider>
  );
};
