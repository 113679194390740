import React, { forwardRef, useContext } from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import { TableVirtuoso } from "react-virtuoso";
import Paper from "@mui/material/Paper";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import DynamicHtml from "../CommonComponent/DynamicHtml";
import DisplayPrescriberNotes from "./DisplayPrescriberNotes";
import DisplayPAForms from "./DisplayPAForms";
import { ConfigurationContext } from "../../../contexts/Configuration";

const DisplayCoverage = forwardRef(
  (
    {
      dynamicColumns,
      tableRows,
      orderBy,
      order,
      onRequestSort,
      prescriberCustomNotes,
      stateId,
      noDataWarning,
      showDataSource,
    },
    ref
  ) => {
    const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
    const { appliedConfiguration } = useContext(ConfigurationContext);
    const [sortConfig, setSortConfig] = React.useState(null);
    const [sortOrder, setsortOrder] = React.useState(0);
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
      if (ref?.current) {
        ref.current.scrollTo(0, 0);
      }
    };

    const isShowCardRow = (currentRow, headcell) => {
      if (!dynamicColumns.some((item) => item.dataField.toLowerCase() === "prescribernotes")) {
        return true;
      }
      var regex = /pa|st|prior authorization|step therapy/i;
      if (!regex.test(headcell)) {
        return true;
      } else {
        return currentRow.IsPrescriberNotePresent;
      }
    };

    const StyledTableRow = styled(TableRow)(() => ({
      "&:nth-of-type(odd)": {
        backgroundColor: "#EFF6F9",
      },
      "&:nth-of-type(even)": {
        backgroundColor: "#ffffff",
      },
    }));

    const sortCoverageClick = () => {
      let clickCount = sortOrder;
      clickCount = clickCount + 1;
      setsortOrder(clickCount);
      if (clickCount == 3) {
        handleSort("CoveredLivesSort");
        setsortOrder(0);
      } else {
        handleSort(dynamicColumns[0].dataField);
      }
    };
    // Function to sort the table rows based on the sortConfig
    const sortTableRows = () => {
      const sortedRows = [...tableRows];
      sortedRows.sort((a, b) => {
        if (sortConfig !== null) {
          const key = sortConfig.key;
          if (a[key] < b[key]) {
            return sortConfig.direction === "ascending" ? -1 : 1;
          }
          if (a[key] > b[key]) {
            return sortConfig.direction === "ascending" ? 1 : -1;
          }
        }
        return 0;
      });
      return sortedRows;
    };
    const getNoUICustomValue = function (row, headCell, isPrescriberNotePresent = false) {
      if ((headCell.dataField.match("PrescriberNotes") || headCell.dataField.match("PAFormIcon")) && isPrescriberNotePresent) {
        return row;
      } else if (row == null || row == undefined || row == "") {
        if (headCell?.noDataUi == null || headCell?.noDataUi == undefined || headCell?.noDataUi == "") {
          return row;
        } else if (headCell?.noDataUi == "Custom Value") {
          return (
            <DynamicHtml
              input={headCell?.customValue}
              part="cfDivCoverageDetail"
              type="label"
              className={
                showDataSource ? "norstella-cf-dataSource" : "norstella-cf-customValue"
              }
            />
          );
        } else if (headCell?.noDataUi == "Dash-") {
          return (
            <span
              className={
                showDataSource
                  ? "norstella-cf-dataSource"
                  : "norstella-cf-spnCustomValueDash"
              }
            >
              -
            </span>
          );
        } else if (headCell?.noDataUi == "Blank Value") {
          return "";
        }
      } else return row;
    };
    function handleSort(key) {
      // If the same column is clicked again, toggle the sort direction
      if (sortConfig && sortConfig.key === key) {
        setSortConfig({
          key: key,
          direction: sortConfig.direction === "ascending" ? "descending" : "ascending",
        });
      } else {
        // Otherwise, set the sortConfig for the new column
        setSortConfig({
          key: key,
          direction: "descending",
        });
      }
    }
    const sortedRows = sortTableRows();

    const VirtuosoTableScroller = React.forwardRef((props, ref) => (
      <TableContainer
        component={Paper}
        {...props}
        ref={ref}
        sx={{ height: 450 }}
        className="norstella-cf-coverageTableContainer"
        id="coverageTableContainer"
      />
    ));
    VirtuosoTableScroller.displayName = "VirtuosoTableScroller";

    const VirtuosoTableBody = React.forwardRef((props, ref) => (
      <>
        {noDataWarning != "" && (
          <TableRow className="norstella-cf-trNoResult">
            <TableCell colSpan={dynamicColumns.length} className="norstella-cf-tcNoResult">
              <span className="norstella-cf-noresultmessage" part="cfNoResultmessage">
                {noDataWarning}
              </span>
            </TableCell>
          </TableRow>
        )}
        <TableBody {...props} className="norstella-cf-displayCoverageTableBody" ref={ref} />
      </>
    ));
    VirtuosoTableBody.displayName = "VirtuosoTableBody";

    const VirtuosoTableComponents = {
      Scroller: VirtuosoTableScroller,
      Table: (props) => (
        <Table
          {...props}
          sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
          className="norstella-cf-displayCoverageTable"
          stickyHeader
          aria-label="coverage table"
        />
      ),
      TableHead,
      // eslint-disable-next-line no-unused-vars
      TableRow: ({ item: _item, ...props }) => (
        <StyledTableRow {...props} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} />
      ),
      TableBody: VirtuosoTableBody,
    };

    function fixedHeaderContent() {
      return (
        <TableRow className="norstella-cf-tableHeaderRow" part="cfTableHeaderRow">
          {dynamicColumns.map((headCell, index) => (
            <TableCell
              key={headCell.dataField}
              className={
                headCell.dataField === "PlanName" && dynamicColumns.length > 3
                  ? "norstella-cf-tableHeaderCell norstella-cf-tableHeaderCellPlanNamePAR"
                  : `norstella-cf-tableHeaderCell norstella-cf-tableHeaderCell${index}`
              }
            >
              <TableSortLabel
                active={orderBy === headCell.dataField && order !== ""}
                direction={orderBy === headCell.dataField && order !== "" ? order : "asc"}
                onClick={createSortHandler(headCell.dataField)}
                className={`norstella-cf-tableSortLabel norstella-cf-tableSortLabel${index}`}
              >
                <DynamicHtml
                  input={headCell.columnTitle}
                  part="cfDivCoverageDetail"
                  type="label"
                  className={`norstella-cf-columnTitle norstella-cf-columnTitle${index}`}
                />
                {orderBy === headCell.dataField ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      );
    }

    function getCustomIconPath(headCell) {
      if (appliedConfiguration && headCell) {
        return appliedConfiguration?.coverageFinderGridColumnIcons?.find(
          (item) =>
            item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
            item.benefitTypeId === headCell.benefitTypeId &&
            item.columnDataField === headCell.dataField
        )?.iconPath;
      }
      return null;
    }

    function rowContent(_index, row) {
      return (
        <>
          {dynamicColumns.map((headCell, headerindex) => (
            <TableCell
              key={headCell.dataField}
              className={`norstella-cf-tableDataCell norstella-cf-tc${headerindex}`}
              component={headerindex === 0 ? "th" : "td"}
            >
              {appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                (item) =>
                  item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                  item.benefitTypeId === headCell.benefitTypeId &&
                  item.dataElement === row[headCell.dataField]
              ) ? (
                <img
                  part="cfDisplayRowCellIcon"
                  className="norstella-cf-DynamicColCellIcon"
                  src={
                    appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                      (item) =>
                        item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                        item.benefitTypeId === headCell.benefitTypeId &&
                        item.dataElement === row[headCell.dataField]
                    ).iconPath
                  }
                  alt="Icon"
                />
              ) : (
                getNoUICustomValue(
                  row[headCell.dataField],
                  headCell,
                  row.IsPrescriberNotePresent
                )
              )}
              {/* PA Form Icon */}
              {headCell.dataField.match("PAFormIcon") && row.IsPrescriberNotePresent ? (
                <DisplayPAForms row={row}
                  customIconPath={getCustomIconPath(headCell)} />
              ) : (
                ""
              )}
              {headCell.dataField.match("PrescriberNotes") && row.IsPrescriberNotePresent ? (
                <DisplayPrescriberNotes
                  row={row}
                  dynamicColumns={dynamicColumns}
                  prescriberCustomNotes={prescriberCustomNotes}
                  stateId={stateId}
                  iconPath={
                    appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                      (item) =>
                        item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                        item.benefitTypeId === headCell.benefitTypeId
                    )?.iconPath
                  }
                />
              ) : (
                ""
              )}
            </TableCell>
          ))}
        </>
      );
    }

    return (
      <div className="norstella-cf-coverage-content" style={{ position: "relative" }} id="coverage-content">
        <div className="norstella-cf-flexAlignResponsive" part="cfDivDisplayCoverage">
          {!matchesMobileWidth && (
            <Paper style={{ height: 450, width: "100%" }} className="norstella-cf-pprTableCoverage">
              <TableVirtuoso
                data={tableRows}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
              />
            </Paper>
          )}
          {matchesMobileWidth && dynamicColumns && dynamicColumns.length > 0 && (
            <div id="mobileCardView" className="norstella-cf-mobileCardView" ref={ref}>
              {noDataWarning != "" && (
                <span className="norstella-cf-noresultmessage" part="cfNoResultmessage">
                  {noDataWarning}
                </span>
              )}
              {noDataWarning == "" && (
                <Card className="norstella-cf-sortCard" variant="outlined">
                  <Button
                    variant="contained"
                    className="norstella-cf-sortButton"
                    id="sortButton"
                    onClick={sortCoverageClick}
                    part="cfSortButton"
                  >
                    Sort
                    {sortConfig &&
                      sortOrder != 0 &&
                      // Render arrow icons based on the current sort direction
                      (sortConfig.direction === "ascending" ? <ArrowUpward /> : <ArrowDownward />)}
                  </Button>
                </Card>
              )}
              {sortedRows.map((row, i) => {
                return (
                  <Card key={i} className="norstella-cf-card" part="cfCard" variant="outlined">
                    <CardContent className="norstella-cf-displayCoverageCardContent">
                      <div className="norstella-cf-spansContainer">
                        {dynamicColumns.map((headCell, headerindex) => {
                          return headerindex === 0 ? (
                            // This will be the first field on the card. Font style will be bold and slightly larger by default. Usually the plan name/row header
                            <span key={headerindex} part="cfSpanPlanHeader" className="norstella-cf-mobilePlanHeader">
                              {appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                                (item) =>
                                  item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                                  item.benefitTypeId === headCell.benefitTypeId &&
                                  item.dataElement === row[headCell.dataField]
                              ) ? (
                                <img
                                  part="cfDisplayRowCellIcon"
                                  className="norstella-cf-DynamicColCellIcon"
                                  src={
                                    appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                                      (item) =>
                                        item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                                        item.benefitTypeId === headCell.benefitTypeId &&
                                        item.dataElement === row[headCell.dataField]
                                    ).iconPath
                                  }
                                  alt="Icon"
                                />
                              ) : (
                                getNoUICustomValue(row[headCell.dataField], headCell,row.IsPrescriberNotePresent)
                              )}
                            </span>
                          ) : (
                            <div
                              key={headCell.dataField}
                              part="cfSpanCoverageDetail"
                              className="norstella-cf-mobilePlanDetails"
                            >
                              {/* Status */}
                              {(headCell.dataField === "Status" ||
                                headCell.columnTitle === "Coverage" ||
                                headCell.isStatusColumn) && (
                                <span className="norstella-cf-mobileCoverageStatus">
                                  {appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                                    (item) =>
                                      item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                                      item.benefitTypeId === headCell.benefitTypeId &&
                                      item.dataElement === row[headCell.dataField]
                                  ) ? (
                                    <img
                                      part="cfDisplayRowCellIcon"
                                      className="norstella-cf-DynamicColCellIcon"
                                      src={
                                        appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                                          (item) =>
                                            item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                                            item.benefitTypeId === headCell.benefitTypeId &&
                                            item.dataElement === row[headCell.dataField]
                                        ).iconPath
                                      }
                                      alt="Icon"
                                    />
                                  ) : (
                                    getNoUICustomValue(row[headCell.dataField], headCell,row.IsPrescriberNotePresent)
                                  )}
                                </span>
                              )}
                              {/* All fields other than prescriber note and status */}
                              {headCell.dataField !== "Status" &&
                                headCell.columnTitle !== "Coverage" &&
                                !headCell.isStatusColumn &&
                                headCell.dataField !== "PrescriberNotes" &&
                                isShowCardRow(row, headCell.dataField) && (
                                  <span part="cfSpanCoverageDetail" className="norstella-cf-mobilePlanDetails">
                                    <DynamicHtml
                                      input={headCell.columnTitle}
                                      part="cfDivCoverageDetail"
                                      className="norstella-cf-mobileCoverageDetail"
                                      type="label"
                                    />
                                    :{" "}
                                    {appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                                      (item) =>
                                        item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                                        item.benefitTypeId === headCell.benefitTypeId &&
                                        item.dataElement === row[headCell.dataField]
                                    ) ? (
                                      <img
                                        part="cfDisplayRowCellIcon"
                                        className="norstella-cf-DynamicColCellIcon"
                                        src={
                                          appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                                            (item) =>
                                              item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                                              item.benefitTypeId === headCell.benefitTypeId &&
                                              item.dataElement === row[headCell.dataField]
                                          ).iconPath
                                        }
                                        alt="Icon"
                                      />
                                    ) : (
                                      getNoUICustomValue(row[headCell.dataField], headCell,row.IsPrescriberNotePresent)
                                    )}
                                  </span>
                                )}
                              {/* Prescriber Note */}
                              {headCell.dataField.match("PrescriberNotes") && row.IsPrescriberNotePresent ? (
                                <DisplayPrescriberNotes
                                  row={row}
                                  dynamicColumns={dynamicColumns}
                                  prescriberCustomNotes={prescriberCustomNotes}
                                  stateId={stateId}
                                  iconPath={
                                    appliedConfiguration?.coverageFinderGridColumnIcons?.find(
                                      (item) =>
                                        item.coverageFinderColumnId === headCell.coverageFinderColumnId &&
                                        item.benefitTypeId === headCell.benefitTypeId
                                    )?.iconPath
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {/* PA Form Icon */}
                              {headCell.dataField.match("PAFormIcon") && row.IsPrescriberNotePresent ? (
                                 <DisplayPAForms row={row}
                                 customIconPath={getCustomIconPath(headCell)} />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </CardContent>
                  </Card>
                );
              })}
            </div>
          )}
        </div>
        <div style={{ display: "none" }} id="hdnPlanNames">
          {/* This div contains all plan/controller names to help with automated testing */}
          {tableRows.map((row) => (row["PlanName"] ? row["PlanName"] : row["ControllerName"])).join("|")}
        </div>
      </div>
    );
  }
);

DisplayCoverage.displayName = "DisplayCoverage";

export default DisplayCoverage;
