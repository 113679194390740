import React from "react";
import { CommonService } from "@services";

const Footer = ({ customFooter }) => {
  customFooter = CommonService.htmlDecode(customFooter ?? "");
  if (customFooter != null) {
    customFooter =CommonService.replaceDatePlaceholders(customFooter);
  }
  return (
    <div className="norstella-cf-footer" part="cfDivFooter">
      <div
        className="norstella-cf-customFooter"
        part="cfDivCustomFooterText"
        dangerouslySetInnerHTML={{ __html: customFooter }}
      ></div>
    </div>
  );
};

export default Footer;
