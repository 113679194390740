export default class CommonService {
  static htmlDecode(input) {
    if (input.includes("&gt") || input.includes("&lt")) {
      var e = document.createElement("div");
      e.innerHTML = input;
      return e.childNodes[0].nodeValue;
    } else {
      return input;
    }
  }
  static parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  static replaceDatePlaceholders(inputString) {
    var currentDate = new Date();
    var year = currentDate.getFullYear().toString();
    var month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    var day = currentDate.getDate().toString().padStart(2, "0");
    var replacedString = inputString
      .replace(/\[MM\/DD\/YYYY\]/gi, month + "/" + day + "/" + year)
      .replace(/\[mm\/dd\/yyyy\]/g, month + "/" + day + "/" + year)
      .replace(/\[mm\/yy\]/g, month + "/" + year.slice(-2))
      .replace(/\[MM\/YY\]/g, month + "/" + year.slice(-2));
    return replacedString;
  }
}
