// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NorstellaCoverageFinder StateMap default styles */

.norstella-cf-map > svg {
  height: 40vh;
  min-height: 300px;
}

.norstella-cf-prmap {
  width: 75px;
  height: 30px;
  margin-left: 63%;
  margin-top: -40px;
}

/* Map default fill color */
.norstella-cf-mapRegion {
  fill: #ebebeb;
  stroke: #3d3d3d;
  stroke-width: 0.25;
}

.norstella-cf-mapRegionHover {
  fill: #00008b;
}

/* Map fill color on selection of state */
.norstella-cf-mapRegionSelected {
  fill: #00008b;
}
.norstella-cf-mapRegionSelectedHover {
  fill: #00008b;
}

/* Map font size on region labels */
.norstella-cf-mapLabel {
  font-size: 10px;
}

/* Map fill color on region labels */
.norstella-cf-mapLabelHover {
  fill: #fff;
}

.norstella-cf-mapLabelSelected {
  fill: #fff;
}

.norstella-cf-mapLabelSelectedHover {
  fill: #fff;
}

.norstella-cf-mapSubHeader {
  font-size: 20px;
  font-weight: bold;
}

.norstella-cf-regionTooltip {
  background-color: #00008b;
  color: #fff;
}

.norstella-cf-markerTooltip {
  background-color: #00008b;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/components/CoverageFinder/CommonComponent/StateMap.css"],"names":[],"mappings":"AAAA,oDAAoD;;AAEpD;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA,2BAA2B;AAC3B;EACE,aAAa;EACb,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA,yCAAyC;AACzC;EACE,aAAa;AACf;AACA;EACE,aAAa;AACf;;AAEA,mCAAmC;AACnC;EACE,eAAe;AACjB;;AAEA,oCAAoC;AACpC;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb","sourcesContent":["/* NorstellaCoverageFinder StateMap default styles */\r\n\r\n.norstella-cf-map > svg {\r\n  height: 40vh;\r\n  min-height: 300px;\r\n}\r\n\r\n.norstella-cf-prmap {\r\n  width: 75px;\r\n  height: 30px;\r\n  margin-left: 63%;\r\n  margin-top: -40px;\r\n}\r\n\r\n/* Map default fill color */\r\n.norstella-cf-mapRegion {\r\n  fill: #ebebeb;\r\n  stroke: #3d3d3d;\r\n  stroke-width: 0.25;\r\n}\r\n\r\n.norstella-cf-mapRegionHover {\r\n  fill: #00008b;\r\n}\r\n\r\n/* Map fill color on selection of state */\r\n.norstella-cf-mapRegionSelected {\r\n  fill: #00008b;\r\n}\r\n.norstella-cf-mapRegionSelectedHover {\r\n  fill: #00008b;\r\n}\r\n\r\n/* Map font size on region labels */\r\n.norstella-cf-mapLabel {\r\n  font-size: 10px;\r\n}\r\n\r\n/* Map fill color on region labels */\r\n.norstella-cf-mapLabelHover {\r\n  fill: #fff;\r\n}\r\n\r\n.norstella-cf-mapLabelSelected {\r\n  fill: #fff;\r\n}\r\n\r\n.norstella-cf-mapLabelSelectedHover {\r\n  fill: #fff;\r\n}\r\n\r\n.norstella-cf-mapSubHeader {\r\n  font-size: 20px;\r\n  font-weight: bold;\r\n}\r\n\r\n.norstella-cf-regionTooltip {\r\n  background-color: #00008b;\r\n  color: #fff;\r\n}\r\n\r\n.norstella-cf-markerTooltip {\r\n  background-color: #00008b;\r\n  color: #fff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
