import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import DynamicHtml from "../CommonComponent/DynamicHtml";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

const PrescriberNoteVerticalDisplay = ({ prescriberNoteResponse, coverageFinderPrescriberNotesFields, section }) => {
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const prescriberNoteResponseLowerCase = prescriberNoteResponse.map((item) =>
    Object.keys(item).reduce((acc, key) => {
      acc[key.toLowerCase()] = item[key];
      return acc;
    }, {})
  )[0];
  const noteFields =
    section != null
      ? coverageFinderPrescriberNotesFields.filter((obj) => obj.prescriberNoteSectionId === section.id)
      : coverageFinderPrescriberNotesFields;
  if (!noteFields || noteFields.length === 0) {
    return <div className="norstella-cf-notedetails">There are no criteria details available.</div>;
  }
  return (
    <TableContainer className="norstella-cf-verticalTableContainer">
      <Table className="norstella-cf-verticalNoteTable">
        <TableBody className="norstella-cf-verticalNoteTableBody">
          {noteFields.some(
            (field) =>
              prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== "" &&
              prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== null &&
              prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== undefined
          ) ? (
            noteFields.map(
              (field, subIndex) =>
                prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== "" &&
                prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== null &&
                prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== undefined && (
                  <TableRow
                    className={`norstella-cf-noteVerticalTableRow norstella-cf-noteVerticalTableRow${subIndex}`}
                    key={field.id}
                  >
                    {!matchesMobileWidth && (
                      <>
                        <TableCell
                          className={`norstella-cf-verticalTableNoteCellTitle norstella-cf-verticalTableNoteCellTitle${subIndex}`}
                          part="cfVerticalTableNoteCellTitle"
                          key={`${field.id}-label`}
                          component={subIndex === 0 ? "th" : "td"}
                          scope={subIndex === 0 ? "row" : ""}
                          style={{ width: "30%" }}
                        >
                          <DynamicHtml
                            className={`norstella-cf-verticalNoteTitle norstella-cf-verticalNoteTitle${subIndex}`}
                            input={field.title}
                            type="label"
                          />
                        </TableCell>
                        <TableCell
                          className={`norstella-cf-verticalTableNoteCellContent norstella-cf-verticalTableNoteCellContent${subIndex}`}
                          part="cfVerticalTableNoteCellContent"
                          key={`${field.id}-content`}
                          component={subIndex === 0 ? "th" : "td"}
                          scope={subIndex === 0 ? "row" : ""}
                          style={{ width: "70%" }}
                        >
                          <DynamicHtml
                            className={
                              field.dataField.toLowerCase() == "lineoftherapy"
                                ? "norstella-cf-lineNoteTitle"
                                : `norstella-cf-verticalNoteValue norstella-cf-verticalNoteValue${subIndex}`
                            }
                            input={prescriberNoteResponseLowerCase[field.dataField.toLowerCase()]}
                            part="cfNoteCell"
                          />
                        </TableCell>
                      </>
                    )}
                    {matchesMobileWidth && (
                      <TableCell
                        part="cfNoteBodyCell"
                        className={`norstella-cf-tableNoteBodyCell norstella-cf-tableNoteBodyCell${subIndex}`}
                        key={field.id}
                        component={subIndex === 0 ? "th" : "td"}
                        scope={subIndex === 0 ? "row" : ""}
                      >
                        <DynamicHtml
                          className={`norstella-cf-verticalNoteTitle norstella-cf-verticalNoteTitle${subIndex}`}
                          input={field.title}
                          type="label"
                        />
                        :
                        <DynamicHtml
                          className={
                            field.dataField.toLowerCase() == "lineoftherapy"
                              ? "norstella-cf-lineNoteTitle"
                              : `norstella-cf-verticalNoteValue norstella-cf-verticalNoteValue${subIndex}`
                          }
                          input={prescriberNoteResponseLowerCase[field.dataField.toLowerCase()]}
                          part="cfNoteCell"
                        />
                      </TableCell>
                    )}
                  </TableRow>
                )
            )
          ) : (
            <TableRow className="norstella-cf-noteVerticalTableRow">
              <TableCell
                colSpan={matchesMobileWidth ? 2 : 3}
                style={{ textAlign: "center" }}
                className="norstella-cf-noteVerticalNoDetails"
              >
                There are no criteria details available.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PrescriberNoteVerticalDisplay;
