export default class AuthenticationService {
  static classInstance = null;
  token = null;
  static getInstance() {
    if (AuthenticationService.classInstance === null) {
      AuthenticationService.classInstance = new AuthenticationService();
    }
    return this.classInstance;
  }

  setToken(token) {
    AuthenticationService.getInstance().token = token;
  }

  async getHeaders() {
    const authService = AuthenticationService.getInstance();
    const headers = {
      headers: {
        Authorization: `Bearer ${authService.token}`,
      },
    };
    return headers;
  }
}
