import React, { useEffect, useState, useRef, useContext } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PendoService } from "@services";
import FilterComponent from "./FilterComponent";
import ProductComponent from "./ProductComponent";
import { ConfigurationContext } from "../../../contexts/Configuration";

const SearchCoverage = ({
  clientProductConfigurations,
  setShowResults,
  onChangeBenefitType,
  onChangeNPI,
  onChangeSearchTerm,
  onChangeIndication,
  handleCoverageSearch,
  handleFilterChange,
  handleProductChange,
  selectedState,
  setSelectedState,
  benefitTypes,
  setResultMessage,
  selectedBenefitType,
  selectedProduct,
  products,
  zipCode,
}) => {
  const { appliedConfiguration } = useContext(ConfigurationContext);
  const [selectedZipCode, setZipCode] = useState(zipCode);
  const [states, setStates] = useState([]);
  const [indications, setIndications] = useState([]);
  const [selectedTerritory, setSelectedTerritory] = useState("");
  const [searchNPI, setSearchNPI] = useState("");
  const [selectedIndication, setSelectedIndication] = useState(null);
  const [message, setMessage] = useState("");
  const [npiMessage, setNpiMessage] = useState("");
  const [filterMessage, setFilterMessage] = useState("");
  const [productMessage, setProductMessage] = useState("");
  const [allowStateSearch, setAllowStateSearch] = useState(false);
  const [stateSearchOnly, setStateSearchOnly] = useState(false);
  const [allowNPISearch, setAllowNPISearch] = useState(false);
  const [allowIndicationSelection, setAllowIndicationSelection] = useState(false);
  const [allowProductSelection, setAllowProductSelection] = useState(false);
  const [geoSearchOpen, setGeoSearchOpen] = useState(false);
  const [indicationMessage, setIndicationMessage] = useState("");
  const [Filters, setFilters] = useState(null);
  const [FilterValue, setFilterValues] = useState([]);
  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [isFilterSame, setIsFilterSame] = useState(true);
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const usZipCodeRegex = new RegExp(/^\d{5}(-\d{4})?(?!-)$/);
  const autocompleteRef = useRef();
  const npiTextFieldRef = useRef();
  const zipTextFieldRef = useRef();
  const indicationTextFieldRef = useRef();
  const searchButtonRef = useRef();

  const filterComponenetProps = {
    Filters: Filters,
    OnFilterChange: OnFilterChange,
    allowIndicationSelection: allowIndicationSelection,
    isFilterSame: isFilterSame,
  };
  const productComponenetProps = {
    products: products,
    OnProductChange: OnProductChange,
  };
  const compareFilterCriteria = (criteria1, criteria2) => {
    if (criteria1.length !== criteria2.length) return false;
    for (let i = 0; i < criteria1.length; i++) {
      if (
        criteria1[i].filterCriteria !== criteria2[i].filterCriteria ||
        // criteria1[i].coverageFinderColumnTitles !== criteria2[i].coverageFinderColumnTitles ||
        criteria1[i].displayOrder !== criteria2[i].displayOrder
      ) {
        return false;
      }
    }
    return true;
  };
  const getFilters = () => {
    let benefitTypeid = selectedBenefitType == "Medical" ? 1 : 2;
    var filterMedical = appliedConfiguration.coverageFinderFilters?.find((filter) => filter.benefitTypeId === 1);
    var filterPharmacy = appliedConfiguration.coverageFinderFilters?.find((filter) => filter.benefitTypeId === 2);
    const isFilterTypeSame = filterMedical?.filterType === filterPharmacy?.filterType;
    const areCriteriaSame =
      filterMedical && filterPharmacy
        ? compareFilterCriteria(filterMedical?.filterCriteria, filterPharmacy?.filterCriteria)
        : false;
    setIsFilterSame(isFilterTypeSame && areCriteriaSame);
    setFilters(appliedConfiguration.coverageFinderFilters?.find((x) => x.benefitTypeId == benefitTypeid && x.enabled));
    if (!(isFilterTypeSame && areCriteriaSame)) {
      setFilterValues([]);
    }
  };
  const autocompleteStyles = {
    width: allowIndicationSelection && allowProductSelection ? "200px" : "300px",
  };

  useEffect(() => {
    let ignore = false;
    let isStateSearchOnly = false;
    let usesFilter = false;
    let allowIndication = false;
    let allowProduct = false;
    async function getConfig() {
      if (!ignore) {
        const indArray = clientProductConfigurations.indications;
        const settings = appliedConfiguration?.settings;
        const cfConfig = appliedConfiguration?.coverageFinderConfiguration;
        let allowState = cfConfig?.geographyType?.toLowerCase() != "zip code only";
        isStateSearchOnly = cfConfig?.geographyType?.toLowerCase() == "state only";
        setStateSearchOnly(isStateSearchOnly);
        setSelectedTerritory(zipCode);
        let allowNPI = false;

        if (settings) {
          allowNPI = settings.findIndex((setting) => setting.settingId === 76 && setting.isEnabled === true) > -1;
          allowIndication =
            settings.findIndex((setting) => setting.settingId === 77 && setting.isEnabled === true) > -1;
          allowProduct = settings.findIndex((setting) => setting.settingId === 83 && setting.isEnabled === true) > -1;
        }

        if (allowIndication && !selectedIndication && indArray != null && indArray.length > 0) {
          setIndications(indArray);
          setAllowIndicationSelection(allowIndication);
        }
        if (allowProduct && products.length > 0) {
          setAllowProductSelection(true);
        }
        setAllowStateSearch(allowState);
        setAllowNPISearch(allowNPI);
        onChangeBenefitType(benefitTypes[0]);
        if (allowState) {
          // Sort the states array by name
          let states = appliedConfiguration.states.sort((a, b) =>
            a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0
          );
          setStates(states);
        }
        let benefitTypeid = selectedBenefitType == "Medical" ? 1 : 2;
        usesFilter = appliedConfiguration.coverageFinderFilters?.some(
          (x) => x.benefitTypeId == benefitTypeid && x.enabled
        );
      }
    }

    function allowAutoZipCodeSearch() {
      return !isStateSearchOnly && !allowIndication && !allowProduct && !usesFilter;
    }

    if (clientProductConfigurations) {
      getConfig();

      if (zipCode && initialPageLoad && allowAutoZipCodeSearch()) {
        searchCoverage();
        setInitialPageLoad(false);
      }
    }
    return () => {
      ignore = true;
    };
  }, [clientProductConfigurations, appliedConfiguration]);

  useEffect(() => {
    getFilters();
  }, [appliedConfiguration, selectedBenefitType, matchesMobileWidth]);

  function OnFilterChange(newValue, isFilterSame) {
    const newArrayValue = Array.isArray(newValue) ? newValue : [newValue];
    setFilterValues(newArrayValue);
    handleFilterChange(newArrayValue, isFilterSame);
  }

  function OnProductChange(newValue) {
    handleProductChange(newValue);
  }
  const handleSelectedIndication = (event, value) => {
    setSelectedIndication(value);
    onChangeIndication(value.id);
    if (value.id === 0) {
      setShowResults(false);
    }
  };

  const handleZipSearch = (event) => {
    setSelectedTerritory(event.target.value);
    onChangeSearchTerm(event.target.value);

    if (event.target.value === "") {
      setShowResults(false);
    }
  };

  const handleSelectedTerritory = (event, value) => {
    setSelectedTerritory(value);
    setZipCode(null);
    onChangeSearchTerm(value);
    if (isNaN(value)) {
      //Set this to handle updating the map with a selected state
      setSelectedState(value);
    } else {
      setSelectedState(null);
    }
    if (value === "") {
      setShowResults(false);
    }
  };

  const handleNPISearch = (event) => {
    setSearchNPI(event.target.value);
    onChangeNPI(event.target.value);
    if (event.target.value === "") {
      setSearchNPI("");
      setShowResults(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode == 13 && event.target.value) {
      searchCoverage();
      if (!isNaN(Number(event.target.value[0]))) {
        event.stopPropagation();
      }
    }
  };

  const handleKeyUp = (event) => {
    if (event.target.value === "") {
      setMessage("");
      setFilterMessage("");
      setProductMessage("");
      setNpiMessage("");
      setIndicationMessage("");
    } else if (allowNPISearch && searchNPI && searchNPI.length == 10) {
      setNpiMessage("");
    } else if (allowIndicationSelection && selectedIndication?.id > 0) {
      setIndicationMessage("");
    } else if (!stateSearchOnly && usZipCodeRegex.test(selectedTerritory)) {
      setMessage("");
    } else if (Filters && filterMessage != "") {
      setFilterMessage("");
    } else if (allowProductSelection && productMessage != "") {
      setProductMessage("");
    }
  };

  const resetFocusToSearchButton = () => {
    if (autocompleteRef?.current) {
      autocompleteRef.current.blur();
    }
    if (npiTextFieldRef?.current) {
      npiTextFieldRef.current.blur();
    }
    if (zipTextFieldRef?.current) {
      zipTextFieldRef.current.blur();
    }
    if (indicationTextFieldRef?.current) {
      indicationTextFieldRef.current.blur();
    }
    if (searchButtonRef?.current) {
      searchButtonRef.current.focus();
    }
  };

  const isValidGeoSearch = () => {
    let foundState = false;
    if ((selectedTerritory) && states.find((st) => st.name.toLowerCase() === selectedTerritory.toLowerCase())
    || (selectedState && states.find((st) => st.name.toLowerCase() === selectedState.toLowerCase()))) {
      foundState = true;
    } else {
      foundState = false;
    }
    return (
      ((stateSearchOnly || allowStateSearch) && foundState) || //Check if valid search by State
      (!stateSearchOnly && usZipCodeRegex.test(selectedTerritory)) || //Check if valid search by ZIP code
      (selectedZipCode !== null && selectedZipCode !== undefined && usZipCodeRegex.test(selectedZipCode)) //if a zip code was passed in to the component default to that for searches
    ); 
  };

  const searchCoverage = () => {
    setMessage("");
    setFilterMessage("");
    setProductMessage("");
    setNpiMessage("");
    setIndicationMessage("");
    setResultMessage("");
    setGeoSearchOpen(false);

    resetFocusToSearchButton();

    if (selectedTerritory && searchNPI) {
      setMessage("You may only choose one field to search by.");
      return;
    }
    let validGeoSearch = false;
    let searchType = "";
    if (isValidGeoSearch()) {
      //Valid Geo Search by ZIP or State
      //pendo track event
      PendoService.getInstance().trackEvent(
        "Search Button Click",
        "searchCoverage > Search Territory",
        selectedTerritory
      );

      validGeoSearch = true;
    } else if (searchNPI && searchNPI.length === 10 && !isNaN(searchNPI)) {
      //Valid NPI Search
      //pendo track event
      PendoService.getInstance().trackEvent("Search Button Click", "searchCoverage > Search NPI", searchNPI);
      validGeoSearch = true;
    } else {
      //If a search is not valid display the appropriate error message
      validGeoSearch = false;
      if (allowIndicationSelection && !selectedIndication?.id && allowProductSelection && !selectedProduct) {
        searchType = "a product";
        setMessage(`Please select ${searchType}.`);
      } else if (allowNPISearch && searchNPI) {
        searchType = "enter a valid NPI";
        setNpiMessage("Please enter a valid NPI.");
      } else if (stateSearchOnly) {
        searchType = "a state";
        setMessage(`Please select ${searchType}.`);
      } else if (allowStateSearch && !stateSearchOnly) {
        searchType = "enter a valid ZIP Code or state";
        setMessage(`Please ${searchType}.`);
      } else {
        searchType = "enter a valid ZIP Code";
        setMessage(`Please ${searchType}.`);
      }
    }

    let validIndication = true;
    if (allowIndicationSelection && !selectedIndication?.id && allowProductSelection && !selectedProduct) {
      setMessage("");
      setIndicationMessage("Please select an indication and a product.");
      validIndication = false;
    } else if (allowIndicationSelection && !selectedIndication?.id && !validGeoSearch) {
      setMessage("");
      setNpiMessage("");
      setIndicationMessage(`Please select an indication and ${searchType}.`);
      validIndication = false;
    } else if (allowIndicationSelection && !selectedIndication?.id) {
      setIndicationMessage("Please select an indication.");
      validIndication = false;
    } else if (allowProductSelection && !selectedProduct && !validGeoSearch) {
      setMessage("");
      setNpiMessage("");
      setProductMessage(`Please select a product and ${searchType}.`);
      validIndication = false;
    } else if (allowProductSelection && !selectedProduct) {
      setProductMessage("Please select a product.");
      validIndication = false;
    } else if (FilterValue.length < 1 && Filters) {
      setFilterMessage("Please select a criteria.");
      return;
    }
    if (validGeoSearch && validIndication) {
      handleCoverageSearch();
    }
  };

  return (
    <>
      <div className="norstella-cf-flexAlignResponsive" part="cfDivSearch">
        {allowIndicationSelection && (
          <>
            <div className="norstella-cf-indicationDiv" part="cfIndicationDiv">
              <div className="norstella-cf-searchLabel norstella-cf-bottomMargin">Indication</div>
              <div className="norstella-cf-flexAlignCenterRow norstella-cf-bottomMargin norstella-cf-rightMargin norstella-cf-searchComponents">
                <Autocomplete
                  className="norstella-cf-searchText"
                  style={!matchesMobileWidth ? autocompleteStyles : {}}
                  disablePortal
                  blurOnSelect
                  handleHomeEndKeys
                  disableClearable
                  id="indicationSelect"
                  options={indications}
                  value={selectedIndication}
                  onChange={handleSelectedIndication}
                  renderInput={(params) => <TextField {...params} placeholder="Select Indication" />}
                  getOptionLabel={(option) => option.name}
                  part="cfIndicationSelect"
                  ref={indicationTextFieldRef}
                />
                <div className="norstella-cf-message" part="cfMessage">
                  {indicationMessage}
                </div>
              </div>
            </div>
          </>
        )}

        {allowProductSelection && (
          <>
            <div className="norstella-cf-productDiv" part="cfProductDiv">
              {allowIndicationSelection && (
                <div className="norstella-cf-searchLabel norstella-cf-bottomMargin">Product</div>
              )}
              <div className="norstella-cf-flexAlignCenterRow norstella-cf-bottomMargin norstella-cf-rightMargin norstella-cf-searchComponents">
                <ProductComponent {...productComponenetProps}></ProductComponent>
                <div className="norstella-cf-message" part="cfMessage">
                  {productMessage}
                </div>
              </div>
            </div>
          </>
        )}

        <div
          className={`norstella-cf-flexAlignCenterRow norstella-cf-bottomMargin ${matchesMobileWidth ? "" : "norstella-cf-searchComponents"}`}
        >
          <div className="norstella-cf-div-col">
            {allowIndicationSelection && (
              <div className="norstella-cf-searchLabel norstella-cf-bottomMargin">Location</div>
            )}
            {!allowStateSearch && (
              <TextField
                id="geoSearch"
                placeholder="Enter ZIP Code"
                part="cfGeoSearch"
                className="norstella-cf-searchText"
                variant="outlined"
                onChange={handleZipSearch}
                onKeyUp={handleKeyUp}
                onKeyDown={handleKeyDown}
                value={selectedTerritory}
                ref={zipTextFieldRef}
              />
            )}
            {allowStateSearch && (
              <Autocomplete
                style={!matchesMobileWidth ? autocompleteStyles : {}}
                disablePortal
                className="norstella-cf-searchText"
                freeSolo
                blurOnSelect
                handleHomeEndKeys
                id="geoSearch"
                open={geoSearchOpen}
                onOpen={() => {
                  setGeoSearchOpen(true);
                }}
                onClose={() => {
                  setGeoSearchOpen(false);
                }}
                value={selectedState ?? selectedTerritory}
                options={states}
                onInputChange={handleSelectedTerritory}
                placeholder={stateSearchOnly ? "Select State" : "Enter ZIP Code or State"}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleKeyUp}
                    placeholder={stateSearchOnly ? "Select State" : "Enter ZIP Code or State"}
                  />
                )}
                getOptionLabel={(option) => option.name ?? option}
                sx={{ width: 300 }}
                part="cfGeoSearch"
                ref={autocompleteRef}
              />
            )}
            {!matchesMobileWidth && (
              <div className="norstella-cf-message" part="cfMessage">
                {message}
              </div>
            )}
          </div>
          {allowNPISearch && (
            <>
              <span className="norstella-cf-searchSeparator">or</span>
              <div className="norstella-cf-div-col">
                {allowIndicationSelection && (
                  <div className="norstella-cf-searchLabel norstella-cf-bottomMargin">NPI</div>
                )}
                <TextField
                  id="npiSearch"
                  placeholder="Enter NPI"
                  className="norstella-cf-npiSearch"
                  part="cfNpiSearch"
                  variant="outlined"
                  onChange={handleNPISearch}
                  onKeyDown={handleKeyDown}
                  onKeyUp={handleKeyUp}
                  ref={npiTextFieldRef}
                />
                {!matchesMobileWidth && (
                  <div className="norstella-cf-message" part="cfMessage">
                    {npiMessage}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
        {matchesMobileWidth && (message !== "" || npiMessage !== "") && (
          <div className="norstella-cf-flexAlignCenterRow norstella-cf-bottomMargin">
            <>
              <div className="norstella-cf-message" part="cfMessage">
                {message}
              </div>
              <div className="norstella-cf-message" part="cfNpimessage">
                {npiMessage}
              </div>
            </>
          </div>
        )}
        {Filters && (
          <>
            <div className="norstella-cf-filterdiv norstella-cf-searchComponents" part="cfFilterDiv">
              {allowIndicationSelection && (
                <div className="norstella-cf-searchLabel norstella-cf-bottomMargin norstella-cf-leftMargin">Filter</div>
              )}
              <div
                className={
                  matchesMobileWidth
                    ? "norstella-cf-div-col"
                    : "norstella-cf-flexAlignCenterRow norstella-cf-bottomMargin norstella-cf-rightMargin"
                }
              >
                <FilterComponent {...filterComponenetProps}></FilterComponent>
                <div
                  className="norstella-cf-message"
                  style={{ marginLeft: matchesMobileWidth ? "0px" : "20px" }}
                  part="cfMessage"
                >
                  {filterMessage}
                </div>
              </div>
            </div>
          </>
        )}

        {!matchesMobileWidth && (
          <Button
            variant="contained"
            className="norstella-cf-searchButton"
            id="searchButton"
            onClick={searchCoverage}
            part="cfSearchButton"
            ref={searchButtonRef}
          >
            Search
          </Button>
        )}
      </div>
      {matchesMobileWidth && (
        <Button
          variant="contained"
          className="norstella-cf-searchButton"
          id="searchButton"
          onClick={searchCoverage}
          part="cfSearchButton"
          ref={searchButtonRef}
        >
          Search
        </Button>
      )}
    </>
  );
};

export default SearchCoverage;
