import React from "react";
import { AppSettingsService } from "@services";

const DebugMessage = () => {
  return (
    <div className="norstella-cf-debugMessage">
      Coverage Finder package version: {AppSettingsService.getInstance().getPackageVersion()}
    </div>
  );
};

export default DebugMessage;
