import React, { useEffect, useState } from "react";
import "./MuiClassNameSetup";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import HCPCoverageFinder from "./HCP/HCPCoverageFinder";
import { AppSettingsService, CommonService, StyleService } from "@services";
import PatientCoverageFinder from "./Patient/PatientCoverageFinder";
import Analytics from "@norstella/nxp-ui-analytics";
import "./style.css";

const CoverageFinder = ({ token, indicationid, environment, zipCode }) => {
  const [websiteType, setWebsiteType] = useState("1");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  useEffect(() => {
    AppSettingsService.getInstance().setEnvironmentAppSettings(environment);
    if (token) {
      const jwtPayload = CommonService.parseJwt(token);
      const tokenProducts = JSON.parse(jwtPayload.products);
      const jwtWebsiteType = jwtPayload.websiteType ?? 1;
      setWebsiteType(jwtWebsiteType);
      setIsLoadingPage(false);
      const cdnUrl = AppSettingsService.getInstance().getCDNUrl();
      if(tokenProducts.length===1)
      StyleService.getInstance().loadCustomFonts(jwtPayload.clientId, cdnUrl, tokenProducts[0].ProductId);
    } else {
      console.log("Token is null or empty");
    }
  }, [token]);

  const analyticsConfig = {
    apiKey: AppSettingsService.getInstance().getPendoKey(),
    initConfig: {
      account: {
        id: CommonService.parseJwt(token).clientId,
      },
      visitor: {
        id: "",
        metadata: CommonService.parseJwt(token).products,
      },
    },
  };

  const theme = createTheme({
    typography: {
      fontFamily: "inherit",
    },
  });

  return (
    <>
      {analyticsConfig?.apiKey && <Analytics provider="pendo" providerConfig={analyticsConfig} />}
      <ThemeProvider theme={theme}>
        {isLoadingPage && (
          <Grid item className="norstella-cf-loaderTable">
            <CircularProgress />
          </Grid>
        )}
        {!isLoadingPage && (websiteType == "1" || websiteType == "3") && (
          <HCPCoverageFinder token={token} indicationid={indicationid} zipCode={zipCode}></HCPCoverageFinder>
        )}
        {!isLoadingPage && websiteType == "2" && (
          <PatientCoverageFinder token={token} indicationid={indicationid}></PatientCoverageFinder>
        )}
      </ThemeProvider>
    </>
  );
};

export default CoverageFinder;
