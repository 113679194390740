import React, { useEffect, useState } from "react";
import jsVectorMap from "jsvectormap";
import "../../../maps/puerto-rico-converted.js";
import "../../../maps/us-merc-en.js";
import "jsvectormap/dist/css/jsvectormap.css";
import "./StateMap.css";

const StateMap = ({ states, selectedState, setSelectedState, setShowResults }) => {
  const [map, setMap] = useState(null);
  const [prMap, setPrMap] = useState(null);
  let initialMap;
  let prmap;

  useEffect(() => {
    let ignore = false;
    var mapRegion = document.querySelector("coverage-finder").shadowRoot.querySelector(".norstella-cf-mapRegion");
    var mapRegionHover = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-mapRegionHover");
    var mapRegionSelected = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-mapRegionSelected");
    var mapRegionSelectedHover = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-mapRegionSelectedHover");
    var mapLabel = document.querySelector("coverage-finder").shadowRoot.querySelector(".norstella-cf-mapLabel");
    var mapLabelHover = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-mapLabelHover");
    var mapLabelSelected = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-mapLabelSelected");
    var mapLabelSelectedHover = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-mapLabelSelectedHover");
    var regionTooltip = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-regionTooltip");
    var markerTooltip = document
      .querySelector("coverage-finder")
      .shadowRoot.querySelector(".norstella-cf-markerTooltip");

    if (!ignore && !initialMap) {
        initialMap = new jsVectorMap({
        selector: document.querySelector("coverage-finder").shadowRoot.querySelector("#map"),
        map: "us_merc_en",
        regionsSelectable: true,
        regionsSelectableOne: true,
        markersSelectable: true,
        markersSelectableOne: true,
        zoomButtons: false,
        zoomOnScroll: false,
        regionStyle: {
          initial: {
            fill: getComputedStyle(mapRegion)?.fill,
            fillOpacity: getComputedStyle(mapRegion)?.fillOpacity,
            stroke: getComputedStyle(mapRegion)?.stroke,
            strokeWidth: getComputedStyle(mapRegion)?.strokeWidth,
          },
          hover: {
            fill: getComputedStyle(mapRegionHover)?.fill,
            fillOpacity: getComputedStyle(mapRegionHover)?.fillOpacity,
            stroke: getComputedStyle(mapRegionHover)?.stroke,
            strokeWidth: getComputedStyle(mapRegionHover)?.strokeWidth,
          },
          selected: {
            fill: getComputedStyle(mapRegionSelected)?.fill,
            fillOpacity: getComputedStyle(mapRegionSelected)?.fillOpacity,
            stroke: getComputedStyle(mapRegionSelected)?.stroke,
            strokeWidth: getComputedStyle(mapRegionSelected)?.strokeWidth,
          },
          selectedHover: {
            fill: getComputedStyle(mapRegionSelectedHover)?.fill,
            fillOpacity: getComputedStyle(mapRegionSelectedHover)?.fillOpacity,
            stroke: getComputedStyle(mapRegionSelectedHover)?.stroke,
            strokeWidth: getComputedStyle(mapRegionSelectedHover)?.strokeWidth,
          },
        },
        // eslint-disable-next-line no-unused-vars
        onMarkerTooltipShow: function (e, tooltip, code) {
          tooltip.css({ backgroundColor: getComputedStyle(markerTooltip)?.backgroundColor, color: getComputedStyle(markerTooltip)?.color });
          e.preventDefault();
        },
        // eslint-disable-next-line no-unused-vars
        onRegionTooltipShow: function (e, tooltip, code) {
          tooltip.css({ backgroundColor: getComputedStyle(regionTooltip)?.backgroundColor, color: getComputedStyle(regionTooltip)?.color });
          e.preventDefault();
        },
        markerStyle: {
          initial: {},
          hover: {},
          selected: {
            fill: getComputedStyle(mapRegionSelected)?.fill,
          },
          selectedHover: {}
        },
        markers: [
          {
            coords: [38.9072, -77.0369],
            name: "DC",
            style: {
              strokeWidth: 3,
              r: 5,
            },
          },
          {
            coords: [36.9072, -74.0369],
            name: "DC-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [41.5801, -71.4774],
            name: "RI",
            style: {
              display: "none",
            },
          },
          {
            coords: [40.5801, -69.0774],
            name: "RI-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [39.2904, -76.6122],
            name: "MD",
            style: {
              display: "none",
            },
          },
          {
            coords: [37.9072, -74.0369],
            name: "MD-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [39.1582, -75.5244],
            name: "DE",
            style: {
              display: "none",
            },
          },
          {
            coords: [39.0072, -73.0369],
            name: "DE-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [40.0919, -74.3587],
            name: "NJ",
            style: {
              display: "none",
            },
          },
          {
            coords: [40.1072, -73.3369],
            name: "NJ-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [41.6658, -72.6734],
            name: "CT",
            style: {
              display: "none",
            },
          },
          {
            coords: [40.6801, -71.0774],
            name: "CT-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [42.3601, -71.0589],
            name: "MA",
            style: {
              display: "none",
            },
          },
          {
            coords: [42.3601, -69.0774],
            name: "MA-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [44.2601, -72.5754],
            name: "VT",
            style: {
              display: "none",
            },
          },
          {
            coords: [46.2601, -72.9754],
            name: "VT-label",
            style: {
              display: "none",
            },
          },
          {
            coords: [43.5279, -71.4704],
            name: "NH",
            style: {
              display: "none",
            },
          },
          {
            coords: [46.5279, -71.4704],
            name: "NH-label",
            style: {
              display: "none",
            },
          },
        ],
        lines: [
          {
            from: "DC",
            to: "DC-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "RI",
            to: "RI-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "MD",
            to: "MD-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "DE",
            to: "DE-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "NJ",
            to: "NJ-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "CT",
            to: "CT-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "MA",
            to: "MA-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "VT",
            to: "VT-label",
            style: {
              stroke: "#000",
            },
          },
          {
            from: "NH",
            to: "NH-label",
            style: {
              stroke: "#000",
            },
          },
        ],
        labels: {
          regions: {
            render(code) {
              return code.split("-")[1];
            },
            offsets(code) {
              return (
                {
                  AK: [30, -20],
                  CA: [-10, 10],
                  CT: [35, 25],
                  DC: [61, 44],
                  DE: [51, 5],
                  FL: [35, 0],
                  GA: [-5, 5],
                  HI: [35, 30],
                  ID: [0, 45],
                  IL: [5, 0],
                  KY: [10, 10],
                  LA: [-17, 0],
                  MA: [57, -4],
                  MD: [67, 22],
                  MI: [30, 40],
                  MN: [-15, 15],
                  MS: [3, 0],
                  NC: [0, -3],
                  NH: [10, -67],
                  NJ: [35, 0],
                  NV: [10, 0],
                  NY: [0, 5],
                  OK: [25, 0],
                  OR: [0, 10],
                  PA: [0, 3],
                  RI: [50, 25],
                  TX: [15, 0],
                  UT: [0, 15],
                  VA: [15, 15],
                  VT: [-16, -62],
                  WA: [10, 0],
                  WI: [0, 10],
                  WV: [-13, 15],
                }[code.split("-")[1]] ?? [0, 0]
              );
            },
          },
        },
        regionLabelStyle: {
          initial: {
            fontSize: getComputedStyle(mapLabel)?.fontSize,
          },
          // Same properties in initial object can be overwritten in hover and selected states.
          hover: {
            fill: getComputedStyle(mapLabelHover)?.fill,
          },
          selected: {
            fill: getComputedStyle(mapLabelSelected)?.fill,
          },
          selectedHover: {
            fill: getComputedStyle(mapLabelSelectedHover)?.fill,
          },
        },
        onRegionSelected: function (index, isSelected, selectedRegions) {
          if (selectedRegions && selectedRegions.length) {
            map?.clearSelectedMarkers();
            const selectedRegion = selectedRegions[0];
            const stateId = selectedRegion?.substring(selectedRegion.length - 2);
            const stateName = states.find((s) => s.stateId === stateId)?.name;
            setShowResults(false);
            setSelectedState(stateName);
            prmap?.clearSelectedRegions();
          }
        },
        // eslint-disable-next-line no-unused-vars
        onMarkerClick: function (event, index) {
          const stateName = states.find((s) => s.stateId === "DC")?.name;
          setShowResults(false);
          setSelectedState(stateName);
        },
        onLoaded(map) {
          window.addEventListener("resize", () => {
            map.updateSize();
          });
        },
      });
      setMap(initialMap);
    }

    if (!ignore && !prmap) {
      prmap = new jsVectorMap({
        selector: document.querySelector("coverage-finder").shadowRoot.querySelector("#prmap"),
        map: "puerto_rico",
        regionsSelectable: true,
        regionsSelectableOne: true,
        zoomButtons: false,
        zoomOnScroll: false,
        regionStyle: {
          initial: {
            fill: getComputedStyle(mapRegion)?.fill,
            fillOpacity: getComputedStyle(mapRegion)?.fillOpacity,
            stroke: getComputedStyle(mapRegion)?.stroke,
            strokeWidth: getComputedStyle(mapRegion)?.strokeWidth,
          },
          hover: {
            fill: getComputedStyle(mapRegionHover)?.fill,
            fillOpacity: getComputedStyle(mapRegionHover)?.fillOpacity,
            stroke: getComputedStyle(mapRegionHover)?.stroke,
            strokeWidth: getComputedStyle(mapRegionHover)?.strokeWidth,
          },
          selected: {
            fill: getComputedStyle(mapRegionSelected)?.fill,
            fillOpacity: getComputedStyle(mapRegionSelected)?.fillOpacity,
            stroke: getComputedStyle(mapRegionSelected)?.stroke,
            strokeWidth: getComputedStyle(mapRegionSelected)?.strokeWidth,
          },
          selectedHover: {
            fill: getComputedStyle(mapRegionSelectedHover)?.fill,
            fillOpacity: getComputedStyle(mapRegionSelectedHover)?.fillOpacity,
            stroke: getComputedStyle(mapRegionSelectedHover)?.stroke,
            strokeWidth: getComputedStyle(mapRegionSelectedHover)?.strokeWidth,
          },
          },
          labels: {
            regions: {
              render(code) {
                return code.split("-")[1];
              },
            },
          },
          regionLabelStyle: {
            initial: {
              fontSize: getComputedStyle(mapLabel)?.fontSize,
            },
            // Same properties in initial object can be overwritten in hover and selected states.
            hover: {
              fill: getComputedStyle(mapLabelHover)?.fill,
            },
            selected: {
              fill: getComputedStyle(mapLabelSelected)?.fill,
            },
            selectedHover: {
              fill: getComputedStyle(mapLabelSelectedHover)?.fill,
            },
          },
          // eslint-disable-next-line no-unused-vars
          onRegionTooltipShow: function (e, tooltip, code) {
            tooltip.css({ backgroundColor: getComputedStyle(regionTooltip)?.backgroundColor, color: getComputedStyle(regionTooltip)?.color });
            e.preventDefault();
          },
          onRegionSelected: function (index, isSelected, selectedRegions) {
            if (selectedRegions && selectedRegions.length) {
              const selectedRegion = selectedRegions[0];
              const stateId = selectedRegion?.substring(selectedRegion.length - 2);
              const stateName = states.find((s) => s.stateId === stateId)?.name;
              setShowResults(false);
              setSelectedState(stateName);
            }
          },
          onLoaded(map) {
            window.addEventListener("resize", () => {
              map.updateSize();
            });
          },
      });
      setPrMap(prmap);
    }

    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    let ignore = false;

    if (!ignore) {
      const stateId = selectedState
        ? states.find((s) => s.name.toLowerCase() === selectedState?.toLowerCase())?.stateId
        : "";
      selectState(stateId);
    }

    return () => {
      ignore = true;
    };
  }, [selectedState]);

  function selectState(stateId) {
    map?.clearSelectedRegions();
    prMap?.clearSelectedRegions();
    map?.clearSelectedMarkers();
    if (stateId === "PR" && prMap) {
      if (stateId) {
        prMap._setSelected("regions", [`US-${stateId}`]);
      }
    }
    else if (map && stateId) {   
      if (stateId == "DC") {
        //DC is the first marker, set that to be selected
        map._setSelected("_markers", [0]);
      }
      else {
        map._setSelected("regions", [`US-${stateId}`]);
      }
    }
  }

  return (
    <>
      <div id="mapSubHeader" className="norstella-cf-mapSubHeader" part="cfMapSubHeader">
        Select a State
      </div>
      <div id="map" className="norstella-cf-map"></div>
      <div id="prmap" className="norstella-cf-prmap"></div>
      <span style={{ display: "none" }} className="norstella-cf-mapRegion"></span>
      <span style={{ display: "none" }} className="norstella-cf-mapRegionHover"></span>
      <span style={{ display: "none" }} className="norstella-cf-mapRegionSelected"></span>
      <span style={{ display: "none" }} className="norstella-cf-mapRegionSelectedHover"></span>
      <span style={{ display: "none" }} className="norstella-cf-mapLabel"></span>
      <span style={{ display: "none" }} className="norstella-cf-mapLabelHover"></span>
      <span style={{ display: "none" }} className="norstella-cf-mapLabelSelected"></span>
      <span style={{ display: "none" }} className="norstella-cf-mapLabelSelectedHover"></span>
      <span style={{ display: "none" }} className="norstella-cf-regionTooltip"></span>
      <span style={{ display: "none" }} className="norstella-cf-markerTooltip"></span>
    </>
  );
};

export default StateMap;
