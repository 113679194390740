import React, { useContext } from "react";
import PAFormDialog from "./PAFormDialog";
import { CoverageService, AppSettingsService, PendoService } from "@services";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { ConfigurationContext } from "../../../contexts/Configuration";
import { SearchContext } from "../../../contexts/Search";

const DisplayPAForms = ({
        row,  
        customIconPath,
    }) => {
    const { appliedConfiguration } = useContext(ConfigurationContext);
    const { benefitType } = useContext(SearchContext);
    const [paFormDialogOpen, setPAFormDialogOpen] = React.useState(false);
    const [isLoadingPage, setIsLoadingPage] = React.useState(false);

    const defaultIconPath = AppSettingsService.getInstance().cdnUrl + "icons/shared/MMIT-icon-PAform-sm.png";

    const handlePopupCloseEvent = () => {
        setPAFormDialogOpen(false);
    };

    const openPAForms = (dataRow) => async () => {
        setIsLoadingPage(true);
        const documentLinkRequest = {
            ProductId: appliedConfiguration.productId,
            IndicationId: appliedConfiguration.indicationId,
            FormularyId: dataRow.FormularyID || dataRow.majority_FormularyID,
            IsMedical: benefitType != "Pharmacy",
            DocumentTypes: ["PA Form"],
        };
        //pendo track event
        PendoService.getInstance().trackEvent("PA Form Request", "openPAForms", dataRow.PlanName || dataRow.ControllerName);
        const documentLinkResponse = await CoverageService.getInstance().getDocumentLinks(documentLinkRequest);
        if (documentLinkResponse.documentLinks && documentLinkResponse.documentLinks.length > 0) {
            let links = documentLinkResponse.documentLinks.split("<br>");
            for (let i = 0; i < links.length; i++) {
                let link = links[i];
                if (link) {
                    let url = getHrefFromHtml(link);
                    if (url) {
                        //pendo track event
                        PendoService.getInstance().trackEvent("PA FORM" +(i+1)+" Url"  , "openPAForms", url);
                        window.open(url, "_blank");
                    }
                }
            }
        }
        else {
            setPAFormDialogOpen(true);
            //pendo track event
            PendoService.getInstance().trackEvent("No PA Form Url", "openPAForms", documentLinkRequest);
        }
        setIsLoadingPage(false);
    };

    const getHrefFromHtml = (htmlString) => {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = htmlString;
        const anchor = tempDiv.querySelector("a");
        return anchor ? anchor.getAttribute("href") : null;
    };

    return (
        <>
            {isLoadingPage && (
                <div className="norstella-cf-overlay">
                    <Grid item className="norstella-cf-loaderTable">
                        <CircularProgress />
                    </Grid>
                </div>
            )}
            <div className="norstella-cf-divPAFormDialogOuter">
                <PAFormDialog
                    isOpen={paFormDialogOpen}
                    onClose={handlePopupCloseEvent}
                />
            </div>
            <button className="norstella-cf-note-button" part="cfNoteButton" onClick={openPAForms(row)}>
                <img
                    part="cfDisplayPAFormIcon"
                    className="norstella-cf-PAFormIcon"
                    src={(customIconPath ? customIconPath : defaultIconPath)}
                    alt="Icon"
                />
            </button>
        </>
    );
};

export default DisplayPAForms;