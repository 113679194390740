import React, { useEffect, useRef, useContext } from "react";
import { Card, CardContent } from "@mui/material";
import PrescriberNotesHorizontalDisplay from "./PrescriberNotesHorizontalDisplay";
import PrescriberNoteVerticalDisplay from "./PrescriberNoteVerticalDisplay";
import DialogBox from "../CommonComponent/DialogBox";
import DynamicHtml from "../CommonComponent/DynamicHtml";
import { ConfigurationContext } from "../../../contexts/Configuration";
import { SearchContext } from "../../../contexts/Search";

const PrescriberNotesDialog = ({
  isOpen,
  onClose,
  isVerticalOrientation,
  prescriberNoteResponse,
  additionalPrescriberNoteCriteria,
  resultname,
}) => {
  const { appliedConfiguration } = useContext(ConfigurationContext);
  const { benefitType } = useContext(SearchContext);
  const benefitTypeId = benefitType == "Pharmacy" ? 2 : 1;
  const header = appliedConfiguration.coverageFinderPrescriberNoteHeaders.filter(
    (obj) => obj.benefitTypeId === benefitTypeId
  )[0];
  const sections = appliedConfiguration.coverageFinderPrescriberNoteSections.filter(
    (obj) => obj.benefitTypeId === benefitTypeId
  );
  const coverageFinderPrescriberNotesFields = appliedConfiguration.coverageFinderPrescriberNotes.filter(
    (obj) => obj.benefitTypeId === benefitTypeId && obj.prescriberNoteSectionId > 0
  );
  const coverageFinderPrescriberNotesFieldsWithoutSection = appliedConfiguration.coverageFinderPrescriberNotes.filter(
    (obj) =>
      obj.benefitTypeId === benefitTypeId && (obj.prescriberNoteSectionId == null || obj.prescriberNoteSectionId === 0)
  );
  const coverageFinderPrescriberNotesFieldsWithExternalFile = appliedConfiguration.coverageFinderPrescriberNotes.filter(
    (obj) => obj.benefitTypeId === benefitTypeId && (obj.externalFile != null || obj.externalFile != "")
  );
  const scrollableContent = useRef(null);
  useEffect(() => {
    if (isOpen) {
      // Reset scroll position to top when dialog box opens
      if (scrollableContent.current) {
        scrollableContent.current.scrollTop = 0;
      }
    }
  }, [isOpen]);
  return (
    <>
      <DialogBox isOpen={isOpen} onClose={onClose}>
        <div ref={scrollableContent} id="scrollable-note-content" className="norstella-cf-scrollable-content">
          <div className="norstella-cf-note-content">
            <Card className="norstella-cf-prescriberNotesCard">
              <div className="norstella-cf-divPrescriberNotesCard">
                {
                  <DynamicHtml
                    input={header != null ? header.header : ""}
                    resultname={resultname}
                    className="norstella-cf-prescriberNoteHeader"
                  />
                }
                {sections.length == 0 &&
                  coverageFinderPrescriberNotesFieldsWithoutSection.length == 0 &&
                  coverageFinderPrescriberNotesFieldsWithExternalFile.length == 0 && (
                    <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteNoDetails" part="cfNoteContent">
                      <div className="norstella-cf-notedetails">There are no criteria details available.</div>
                    </Card>
                  )}
                {!isVerticalOrientation && sections != null && (
                  <>
                    <CardContent className="norstella-cf-prescriberNotesCardContentHorizontal">
                      {sections.map((section, i) => (
                        <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteCardHorizontal" part="cfNoteContent" key={i}>
                          <DynamicHtml
                            input={section.sectionName}
                            className="norstella-cf-prescriberNoteSection"
                          ></DynamicHtml>
                          {prescriberNoteResponse != null &&
                            coverageFinderPrescriberNotesFields != null &&
                            section.orientationId == 1 && (
                              <PrescriberNotesHorizontalDisplay
                                section={section}
                                prescriberNoteResponse={prescriberNoteResponse}
                                coverageFinderPrescriberNotesFields={coverageFinderPrescriberNotesFields}
                              />
                            )}

                          {prescriberNoteResponse != null &&
                            coverageFinderPrescriberNotesFields != null &&
                            section.orientationId == 2 && (
                              <PrescriberNoteVerticalDisplay
                                section={section}
                                prescriberNoteResponse={prescriberNoteResponse}
                                coverageFinderPrescriberNotesFields={coverageFinderPrescriberNotesFields}
                              />
                            )}
                          {prescriberNoteResponse == null ||
                            (coverageFinderPrescriberNotesFields == null && (
                              <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteCardHorizontalNoDetails" part="cfNoteContent">
                                <div className="norstella-cf-notedetails">There are no criteria details available.</div>
                              </Card>
                            ))}
                        </Card>
                      ))}
                    </CardContent>
                  </>
                )}
                {coverageFinderPrescriberNotesFieldsWithoutSection.length > 0 && (
                  <>
                    <CardContent className="norstella-cf-noteWithoutSectionContent">
                      <div>
                        {prescriberNoteResponse != null &&
                          coverageFinderPrescriberNotesFieldsWithoutSection != null && (
                            <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteCardWithoutSection" part="cfNoteContent">
                              <PrescriberNoteVerticalDisplay
                                prescriberNoteResponse={prescriberNoteResponse}
                                coverageFinderPrescriberNotesFields={coverageFinderPrescriberNotesFieldsWithoutSection}
                              />
                            </Card>
                          )}
                        {prescriberNoteResponse == null ||
                          (coverageFinderPrescriberNotesFields == null && (
                            <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteCardWithoutSectionNoDetails" part="cfNoteContent">
                              <div className="norstella-cf-notedetails">There are no criteria details available.</div>
                            </Card>
                          ))}
                      </div>
                    </CardContent>
                  </>
                )}
                {isVerticalOrientation && sections != null && (
                  <>
                    <CardContent className="norstella-cf-prescriberNotesCardContentVertical">
                      {sections.map((section, i) => (
                        <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteCardVertical" part="cfNoteContent" key={i}>
                          <DynamicHtml
                            input={section.sectionName}
                            className="norstella-cf-prescriberNoteSection"
                          ></DynamicHtml>
                          {prescriberNoteResponse != null && coverageFinderPrescriberNotesFields != null && (
                            <PrescriberNoteVerticalDisplay
                              section={section}
                              prescriberNoteResponse={prescriberNoteResponse}
                              coverageFinderPrescriberNotesFields={coverageFinderPrescriberNotesFields}
                            />
                          )}
                          {prescriberNoteResponse == null ||
                            (coverageFinderPrescriberNotesFields == null && (
                              <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteCardVerticalNoDetails" part="cfNoteContent">
                                <div className="norstella-cf-notedetails">There are no criteria details available.</div>
                              </Card>
                            ))}
                        </Card>
                      ))}
                    </CardContent>
                  </>
                )}
                {additionalPrescriberNoteCriteria &&
                  additionalPrescriberNoteCriteria.length > 0 &&
                  coverageFinderPrescriberNotesFieldsWithExternalFile &&
                  coverageFinderPrescriberNotesFieldsWithExternalFile.length > 0 && (
                    <>
                      <CardContent className="norstella-cf-prescriberNotesCardContentAdditional">
                        {additionalPrescriberNoteCriteria.map((additional, i) => (
                          <>
                            {additional != null && (
                              <div className="norstella-cf-additionalNoteDiv" key={i}>
                                <Card className="norstella-cf-prescriberNoteCardContent norstella-cf-prescriberNoteCardAdditionalData" part="cfNoteContent">
                                  <PrescriberNoteVerticalDisplay
                                    prescriberNoteResponse={additional}
                                    coverageFinderPrescriberNotesFields={
                                      coverageFinderPrescriberNotesFieldsWithExternalFile
                                    }
                                  />
                                </Card>
                              </div>
                            )}
                          </>
                          // </div>
                        ))}
                      </CardContent>
                      <div className="norstella-cf-divPrescriberNotesAdditionalPadding" style={{ padding: "10px" }}></div>
                    </>
                  )}
              </div>
            </Card>
          </div>
        </div>
      </DialogBox>
    </>
  );
};

export default PrescriberNotesDialog;
