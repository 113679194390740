import React from "react";
import { CommonService } from "@services";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const PatientCardDetails = ({ selectedPlanCardDetails }) => {
  selectedPlanCardDetails = CommonService.htmlDecode(selectedPlanCardDetails ?? "");
  return (
    <Card style={{ width: "100%" }} className="norstella-cf-card" part="cfCard" variant="outlined">
      <CardContent className="norstella-cf-patientCardContent">
        <div>
          {selectedPlanCardDetails && selectedPlanCardDetails.trim() !== "" ? (
            <div
              className="norstella-cf-planCardDetails"
              part="cfDivselectedPlanCardDetailsText"
              dangerouslySetInnerHTML={{ __html: selectedPlanCardDetails }}
            />
          ) : (
            ""
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default PatientCardDetails;
