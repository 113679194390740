import React, { useContext } from "react";
import { CommonService } from "@services";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import CircularProgress from "@mui/material/CircularProgress";
import PrescriberNotesDialog from "../PrescriberNote/PrescriberNotesDialog";
import { CoverageService } from "@services";
import { PendoService } from "@services";
import { SearchContext } from "../../../contexts/Search";
import { ConfigurationContext } from "../../../contexts/Configuration";

const PatientPANotificationPopup = ({ 
  selectedPlan,
  stateId,
  notificationMessage, 
  buttonText 
}) => {
  
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const { appliedConfiguration } = useContext(ConfigurationContext);
  const { benefitType } = useContext(SearchContext);
  const [open, setOpen] = React.useState(false);
  const [prescriberNoteResponse, setPrescriberNoteResponse] = React.useState(null);
  const [additionalPrescriberNoteCriteria, setAdditionalPrescriberNoteCriteria] = React.useState(null);
  const [isVerticalOrientation, setVerticalOrientation] = React.useState(true);
  const [isLoadingPage, setIsLoadingPage] = React.useState(false);
  const [currentPlanName, setCurrentPlanName] = React.useState(selectedPlan);

  let selectedNotificationMessage = CommonService.htmlDecode(notificationMessage ?? "Your doctor may still need to provide more documentation. in order for your insurance to determine if you meet their medical requirements.");
  let selectedbuttonText = CommonService.htmlDecode(buttonText ?? "View Requirements");

  const handleClose = () => {
    setOpen(false);
  };

  const showNotePopup = async () => {
    //pendo track event
    PendoService.getInstance().trackEvent(
      selectedbuttonText,
      "showNotePopup",
      selectedPlan?.PlanName || selectedPlan?.ControllerName
    );
    setIsLoadingPage(true);
    setCurrentPlanName(selectedPlan?.PlanName || selectedPlan?.ControllerName);
    
    const prescriberNoteRequest = {
      ProductId: appliedConfiguration.productId,
      IndicationId: appliedConfiguration.indicationId,
      FormularyId: selectedPlan?.FormularyID || selectedPlan?.majority_FormularyID,
      IsMedical: benefitType?.toLowerCase() != "pharmacy",
      ProjectId: appliedConfiguration.projectId,
      PlanId: selectedPlan?.PlanId,
      ControllerId: selectedPlan?.ControllerId,
      Channel: selectedPlan?.Channel,
      StateId: stateId,
      ResultName:
        benefitType?.toLowerCase() != "pharmacy"
          ? appliedConfiguration.coverageFinderConfiguration.resultName
          : appliedConfiguration.coverageFinderConfiguration.pharmacyResultName,
    };
    const prescriberNoteResponse = await CoverageService.getInstance().getPrescriberNotes(prescriberNoteRequest);
    setOpen(true);
    if (prescriberNoteResponse != null) {
      setPrescriberNoteResponse([prescriberNoteResponse.prescriberNoteCriteria]);
      setAdditionalPrescriberNoteCriteria([prescriberNoteResponse.additionalPrescriberNoteCriteria]);
      setVerticalOrientation(matchesMobileWidth);
    }
    setIsLoadingPage(false);
  };

  return (
    <>
      {selectedNotificationMessage && selectedNotificationMessage.trim() !== "" ? (
        <Card className="norstella-cf-notificationCard">
        <Box className="norstella-cf-notificationBox">
          <InfoOutlinedIcon className="norstella-cf-notificationIcon" />
          <Typography 
            variant="body2" 
            dangerouslySetInnerHTML={{ __html: selectedNotificationMessage }} 
            className="norstella-cf-notificationMessage"
          />
        </Box>
        <Button onClick={showNotePopup} variant="contained" className="norstella-cf-button">
          <span dangerouslySetInnerHTML={{ __html: selectedbuttonText }} />
        </Button>
      </Card>
      
      ) : ""
      }
      {isLoadingPage && (
        <div className="norstella-cf-overlay">
          <Grid item className="norstella-cf-loaderTable">
            <CircularProgress />
          </Grid>
        </div>
      )}
      {open && <div onClick={handleClose} part="cfOverlay" className="norstella-cf-overlay"></div>}
      <div className="norstella-cf-divPrescriberNotesDialogOuter">
        <PrescriberNotesDialog
          isOpen={open}
          onClose={handleClose}
          isVerticalOrientation={isVerticalOrientation}
          prescriberNoteResponse={prescriberNoteResponse}
          additionalPrescriberNoteCriteria={additionalPrescriberNoteCriteria}
          resultname={currentPlanName}
        />
      </div>
    </>
  );
};

export default PatientPANotificationPopup;
