export default class PendoService {
  static classInstance = null;
  static apiUrl = null;
  static cdnUrl = null;
  static getInstance() {
    if (PendoService.classInstance === null) {
      PendoService.classInstance = new PendoService();
    }
    return this.classInstance;
  }

  trackEvent(eventName, eventSource, eventDetail) {
    if (`${process.env.REACT_APP_ENABLE_PENDO}` == "false") {
      return;
    }
    pendo.track("" + eventName + "", {
      EventSource: "" + eventSource + "",
      EventDetail: "" + eventDetail + "",
    });
  }

  updateMetadata(indicationName) {
    pendo.updateOptions({
      visitor: {
        indicationname: indicationName,
      },
    });
  }
}
