export default class PrescriberNoteService {
  
    static IsPrescriberNotePresent(currentRow, dynamicColumns, benefitType) {

        const coverageDataField = dynamicColumns?.find(
          (x) => x.columnTitle.toLowerCase() === "coverage" || x.isStatusColumn
        )?.dataField;
        
        const paDataField = dynamicColumns?.find((x) =>
          ["pa", "prior authorization"].includes(x.columnTitle.toLowerCase())
        )?.dataField;

        const stDataField = dynamicColumns?.find((x) =>
          ["st", "step therapy"].includes(x.columnTitle.toLowerCase())
        )?.dataField;

        const status = currentRow[coverageDataField]?.trim()?.toLowerCase();

        if (benefitType === "Pharmacy") {
          if (status === "not covered" || status === "unknown" || status === "*** not mapped ***") {
            return false;
          } 
          else if (
            (currentRow[paDataField]?.trim()?.toLowerCase() === "no" || currentRow[paDataField]?.trim() === "") &&
            (currentRow[stDataField]?.trim()?.toLowerCase() === "no" || currentRow[stDataField]?.trim() === "")
          ) {
            return false;
          }
        } 
        else if (benefitType === "Medical" && ((status && !status.startsWith("covered")) || status == undefined)) {
          return false;
        }

        return true;
      }
  }
  