import React from "react";
import { CommonService } from "@services";

const Statement = ({ dynamicStatement }) => {
  dynamicStatement = CommonService.htmlDecode(dynamicStatement ?? "");
  return (
    <>
      {dynamicStatement && dynamicStatement.trim() !== "" && (
        <div
          className="norstella-cf-dynamicStatement norstella-cf-bottomMargin"
          part="cfDivDynamicStatementText"
          dangerouslySetInnerHTML={{ __html: dynamicStatement }}
        />
      )}
    </>
  );
};

export default Statement;
