import React from "react";
import DynamicHtml from "../CommonComponent/DynamicHtml";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";

const PrescriberNotesHorizontalDisplay = ({ prescriberNoteResponse, coverageFinderPrescriberNotesFields, section }) => {
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");

  const prescriberNoteResponseLowerCase = prescriberNoteResponse.map((item) =>
    Object.keys(item).reduce((acc, key) => {
      acc[key.toLowerCase()] = item[key];
      return acc;
    }, {})
  )[0];
  const noteFields = coverageFinderPrescriberNotesFields.filter((obj) => obj.prescriberNoteSectionId === section.id);
  if (!noteFields || noteFields.length === 0) {
    return <p>There are no criteria details available.</p>;
  }
  return (
    <div className="norstella-cf-flexAlignResponsive" part="cfDivDisplayCoverage">
      <TableContainer className="norstella-cf-noteHorizonTableContainer">
        <Table className="norstella-cf-prescriberNoteContainer" part="cfNoteContainer">
          {noteFields.some(
            (field) =>
              prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== "" &&
              prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== null &&
              prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== undefined
          ) ? (
            <>
              <TableHead className="norstella-cf-noteHorizonTableHead">
                <TableRow className="norstella-cf-tableHeaderRow" part="cfTableHeaderRow">
                  {noteFields.map(
                    (headCell, index) =>
                      prescriberNoteResponseLowerCase[headCell.dataField.toLowerCase()] !== "" &&
                      prescriberNoteResponseLowerCase[headCell.dataField.toLowerCase()] !== null &&
                      prescriberNoteResponseLowerCase[headCell.dataField.toLowerCase()] !== undefined && (
                        <TableCell
                          key={headCell.id}
                          className={`norstella-cf-tableHeaderCell norstella-cf-tableHeaderCell${index}`}
                          part="cfTableHeaderCell"
                        >
                        <DynamicHtml input={headCell.title}  type="label" />
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableHead>
              <TableBody className="norstella-cf-noteHorizonTableBody">
                <TableRow className="norstella-cf-noteHorizonTableRow">
                  {noteFields.map(
                    (field, headerindex) =>
                      prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== "" &&
                      prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== null &&
                      prescriberNoteResponseLowerCase[field.dataField.toLowerCase()] !== undefined && (
                        <TableCell
                          className={`norstella-cf-tableNoteBodyCell norstella-cf-tableNoteBodyCell${headerindex}`}
                          part="cfTableNoteBodyCell"
                          key={field.id}
                          scope={headerindex === 0 ? "row" : ""}
                        >
                          <DynamicHtml
                            input={prescriberNoteResponseLowerCase[field.dataField.toLowerCase()]}
                            part="cfNoteCell"
                            className={`norstella-cf-NoteCell norstella-cf-tableNoteCell${headerindex}`}
                          />
                        </TableCell>
                      )
                  )}
                </TableRow>
              </TableBody>
            </>
          ) : (
            <TableRow className="norstella-cf-noteVerticalTableRow">
              <TableCell
                colSpan={matchesMobileWidth ? 2 : 3}
                style={{ textAlign: "center" }}
                className="norstella-cf-noteNoCriteria"
              >
                There are no criteria details available.
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
    </div>
  );
};

export default PrescriberNotesHorizontalDisplay;
