import React from "react";

const PAFormDialog = ({
  isOpen,
  onClose,
}) => {
  return (
    <div className={`norstella-cf-popup norstella-cf-popupPAForm ${isOpen ? "norstella-cf-open" : ""}`}>
        <div className="norstella-cf-paFormDialog" part="cfDivPAFormDialog">
            No PA forms are available for this payer.
            <button className="norstella-cf-paFormOkBtn" onClick={onClose}>Ok</button>
        </div>
    </div>
  );
};

export default PAFormDialog;
