import React, { useEffect, useRef } from "react";
import "./DialogBox.css";

const DialogBox = ({ isOpen, onClose, children }) => {
  const popupContentRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      // Reset scroll position to top when dialog box opens
      if (popupContentRef.current) {
        popupContentRef.current.scrollTop = 0;
      }
    }
  }, [isOpen]);
  return (
    <div className={`norstella-cf-popup ${isOpen ? "norstella-cf-open" : ""}`}>
      <div ref={popupContentRef} className="norstella-cf-popup-content">
        <button className="norstella-cf-close-button" onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default DialogBox;
