import React, { useState } from "react";

export const SearchContext = React.createContext();


export const SearchProvider = ({ children }) => {

  const [searchTermDisplay, setSearchTermDisplay] = useState("");
  const [benefitType, setBenefitType] = useState("");

  return (
    <SearchContext.Provider value={{searchTermDisplay, setSearchTermDisplay, benefitType, setBenefitType}}>
    {children}
  </SearchContext.Provider>
  );
};
