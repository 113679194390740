import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import PrescriberNotesDialog from "../PrescriberNote/PrescriberNotesDialog";
import PrescriberCustomNoteDialog from "../PrescriberNote/PrescriberCustomNoteDialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CoverageService } from "@services";
import EditNote from "@mui/icons-material/EditNote";
import { PendoService } from "@services";
import { ConfigurationContext } from "../../../contexts/Configuration";
import { SearchContext } from "../../../contexts/Search";

const DisplayPrescriberNotes = ({
  row,
  dynamicColumns,
  prescriberCustomNotes,
  stateId,
  iconPath,
}) => {
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const { appliedConfiguration } = useContext(ConfigurationContext);
  const { benefitType } = useContext(SearchContext);
  const [open, setOpen] = React.useState(false);
  const [customNotePopupOpen, setCustomNotePopupOpen] = React.useState(false);
  const [customNoteData, setCustomNoteData] = React.useState(null);
  const [prescriberNoteResponse, setPrescriberNoteResponse] = React.useState(null);
  const [additionalPrescriberNoteCriteria, setAdditionalPrescriberNoteCriteria] = React.useState(null);
  const [isVerticalOrientation, setVerticalOrientation] = React.useState(true);
  const [isLoadingPage, setIsLoadingPage] = React.useState(false);
  const [currentPlanName, setCurrentPlanName] = React.useState(null);

  const filterPrescriberCustomNotesByBenefitType = () => {
    if (!prescriberCustomNotes || !prescriberCustomNotes.length) {
      return null;
    }
    const benefitTypeId = benefitType === "Medical" ? 1 : benefitType === "Pharmacy" ? 2 : 3;
    return prescriberCustomNotes.filter((note) => note.benefitTypeId === benefitTypeId);
  };

  const shouldShowCustomNotePopup = (filteredPrescriberCustomNotes, currentRow) => {
    if (!filteredPrescriberCustomNotes || !filteredPrescriberCustomNotes.length) {
      return false;
    }

    let statusDataField = dynamicColumns?.find((x) => x.isStatusColumn)?.dataField;
    if (!statusDataField) {
      statusDataField = "Status";
    }

    function checkCustomNoteApplies(customNote) {
      const noteStatuses = customNote.coverageStatuses?.split(",");

      if (
        (noteStatuses?.includes("Not Covered") && currentRow[statusDataField] == "Not Covered") ||
        (noteStatuses?.includes("Unknown") && currentRow[statusDataField] == "Unknown")
      ) {
        return true;
      } else if (noteStatuses?.includes("Covered")) {
        return currentRow.PA?.trim()?.toLowerCase() == "no" && currentRow.ST?.trim()?.toLowerCase() == "no";
      } else {
        return false;
      }
    }

    const currentRowCustomNoteData = filteredPrescriberCustomNotes.filter(checkCustomNoteApplies);

    let showCustomNotePopup = currentRowCustomNoteData && currentRowCustomNoteData?.length > 0;

    if (showCustomNotePopup) {
      setCustomNoteData(currentRowCustomNoteData[0]);
    }

    return showCustomNotePopup;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCustomNotePopupCloseEvent = () => {
    setCustomNotePopupOpen(false);
  };

  const handleClickOutside = () => {
    setOpen(false);
    setCustomNotePopupOpen(false);
  };

  const showNotePopup = (property) => async () => {
    //pendo track event
    PendoService.getInstance().trackEvent(
      "ShowNote click",
      "showNotePopup",
      property.PlanName || property.ControllerName
    );

    setIsLoadingPage(true);
    const filteredPrescriberCustomNotes = filterPrescriberCustomNotesByBenefitType();
    const shouldShowNotePopup = shouldShowCustomNotePopup(filteredPrescriberCustomNotes, property);
    setCurrentPlanName(property.PlanName || property.ControllerName);
    if (shouldShowNotePopup) {
      setIsLoadingPage(false);
      setOpen(false);
      setCustomNotePopupOpen(true);
    } else {
      setCustomNotePopupOpen(false);
      const prescriberNoteRequest = {
        ProductId: appliedConfiguration.productId,
        IndicationId: appliedConfiguration.indicationId,
        FormularyId: property.FormularyID || property.majority_FormularyID,
        IsMedical: benefitType != "Pharmacy",
        ProjectId: appliedConfiguration.projectId,
        PlanId: property.PlanId,
        ControllerId: property.ControllerId,
        Channel: property.Channel,
        StateId: stateId,
        ResultName:
          benefitType != "Pharmacy"
            ? appliedConfiguration.coverageFinderConfiguration.resultName
            : appliedConfiguration.coverageFinderConfiguration.pharmacyResultName,
      };
      const prescriberNoteResponse = await CoverageService.getInstance().getPrescriberNotes(prescriberNoteRequest);
      setOpen(true);
      if (prescriberNoteResponse != null) {
        updatePAFormLinks(prescriberNoteResponse.prescriberNoteCriteria);
        setPrescriberNoteResponse([prescriberNoteResponse.prescriberNoteCriteria]);
        setAdditionalPrescriberNoteCriteria([prescriberNoteResponse.additionalPrescriberNoteCriteria]);
        setVerticalOrientation(matchesMobileWidth);
      }
      setIsLoadingPage(false);
    }
  };

  const updatePAFormLinks = (prescriberNoteResponse) => {
    const PAFormLinkFieldName = "PA_Form_Links" ;
    if(prescriberNoteResponse[PAFormLinkFieldName] && prescriberNoteResponse[PAFormLinkFieldName] !== "")
      prescriberNoteResponse[PAFormLinkFieldName] = addTargetBlankToUrls(prescriberNoteResponse[PAFormLinkFieldName]);
  }

  function addTargetBlankToUrls(htmlString) {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    const links = tempDiv.querySelectorAll('a');
    links.forEach(link => {
        link.setAttribute('target', '_blank');
        link.setAttribute('rel', 'noopener noreferrer');
    });
    return tempDiv.innerHTML;
  }

  return (
    <>
      {isLoadingPage && (
        <div className="norstella-cf-overlay">
          <Grid item className="norstella-cf-loaderTable">
            <CircularProgress />
          </Grid>
        </div>
      )}
      {open && <div onClick={handleClickOutside} part="cfOverlay" className="norstella-cf-overlay"></div>}
      <div className="norstella-cf-divPrescriberNotesDialogOuter">
        <PrescriberNotesDialog
          isOpen={open}
          onClose={handleClose}
          isVerticalOrientation={isVerticalOrientation}
          prescriberNoteResponse={prescriberNoteResponse}
          additionalPrescriberNoteCriteria={additionalPrescriberNoteCriteria}
          resultname={currentPlanName}
        />
      </div>
      {customNotePopupOpen && (
        <div onClick={handleClickOutside} part="cfOverlay" className="norstella-cf-overlay"></div>
      )}
      <div className="norstella-cf-divPrescriberCustomNoteDialogOuter">
        <PrescriberCustomNoteDialog
          isOpen={customNotePopupOpen}
          onClose={handleCustomNotePopupCloseEvent}
          noteDetails={customNoteData?.noteDetails}
          resultname={currentPlanName}
        />
      </div>
      <button className="norstella-cf-note-button" part="cfNoteButton" onClick={showNotePopup(row)}>
        {iconPath ?  <img part="cfNoteRowCellIcon" className="norstella-cf-DynamicColCellIcon" src={iconPath} alt="Icon" /> : <EditNote part="cfNoteRowCellIcon" className="norstella-cf-tableRowCellIcon"></EditNote>}
        <span part="cfNoteRowText" className="norstella-cf-tableRowCellNoteText">
          Show Details
        </span>
      </button>
    </>
  );
};

export default DisplayPrescriberNotes;
