import React, { useState, useContext } from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { PendoService } from "@services";
import { SearchContext } from "../../../contexts/Search";

const BenefitTypeButton = ({ searchNPI, selectedBenefitType, benefitTypes, onChangeBenefitType,disableBenefitType }) => {
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const { searchTermDisplay } = useContext(SearchContext);
  const [benefitType, setBenefitType] = useState(selectedBenefitType);
  const handleBenefitType = (event, newBenefitType) => {
    //pendo track event
    PendoService.getInstance().trackEvent("Change in BenefitType", "handleBenefitType", newBenefitType);
    setBenefitType(newBenefitType);
    onChangeBenefitType(newBenefitType);
  };
  return (
    <div>
      {matchesMobileWidth && (searchTermDisplay || searchNPI) && (
        <>
          {benefitTypes?.length > 1 && (
            <>
              <label className="norstella-cf-benefitLabel">Patient Benefit Type</label>
              <ToggleButtonGroup
                exclusive
                value={benefitType}
                onChange={handleBenefitType}
                className="norstella-cf-leftMargin norstella-cf-benefitTypeButtonGroup"
                part="cfBenefitTypeButtonGroup"
                disabled={disableBenefitType}
              >
                {benefitTypes.map((benefitType) => (
                  <ToggleButton
                    key={benefitType}
                    value={benefitType}
                    className="norstella-cf-benefitTypeButton"
                    part="cfBenefitTypeButton"
                  >
                    {benefitType}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </>
          )}
        </>
      )}
      {!matchesMobileWidth && (searchTermDisplay || searchNPI) && (
        <>
          {benefitTypes?.length > 1 && (
            <>
              <div className="norstella-cf-benefitTypeDiv">
                <label className="norstella-cf-benefitLabel">Patient Benefit Type</label>
                <ToggleButtonGroup
                  exclusive
                  value={benefitType}
                  onChange={handleBenefitType}
                  className="norstella-cf-leftMargin norstella-cf-benefitTypeButtonGroup"
                  part="cfBenefitTypeButtonGroup"  disabled={disableBenefitType}
                >
                  {benefitTypes.map((benefitType) => (
                    <ToggleButton
                      key={benefitType}
                      value={benefitType}
                      className="norstella-cf-benefitTypeButton"
                      part="cfBenefitTypeButton"
                    >
                      {benefitType}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BenefitTypeButton;
