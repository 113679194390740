export default class AppSettingsService {
  static classInstance = null;
  static apiUrl = null;
  static cdnUrl = null;
  static pendoKey = null;
  static getInstance() {
    if (AppSettingsService.classInstance === null) {
      AppSettingsService.classInstance = new AppSettingsService();
    }
    return this.classInstance;
  }

  getApiUrl() {
    return this.apiUrl;
  }

  getCDNUrl() {
    return this.cdnUrl;
  }

  getPendoKey() {
    return this.pendoKey;
  }

  getPackageVersion() {
    return process.env.REACT_APP_VERSION;
  }

  setEnvironmentAppSettings(environment) {
    let url = `${process.env.REACT_APP_PROD_CF_API_URL}`;
    let cdnUrl = `${process.env.REACT_APP_PROD_CDN_URL}`;
    let pendoKey = `${process.env.REACT_APP_PENDO_KEY_PROD}`;

    const environmentLower = environment?.toLowerCase();
    if (environmentLower === "local") {
      url = `${process.env.REACT_APP_LOCAL_CF_API_URL}`;
      cdnUrl = `${process.env.REACT_APP_LOCAL_CDN_URL}`;
      pendoKey = `${process.env.REACT_APP_PENDO_KEY_DEV}`;
    } else if (environmentLower === "dev") {
      url = `${process.env.REACT_APP_DEV_CF_API_URL}`;
      cdnUrl = `${process.env.REACT_APP_DEV_CDN_URL}`;
      pendoKey = `${process.env.REACT_APP_PENDO_KEY_DEV}`;
    } else if (environmentLower === "test") {
      url = `${process.env.REACT_APP_TEST_CF_API_URL}`;
      cdnUrl = `${process.env.REACT_APP_TEST_CDN_URL}`;
      pendoKey = `${process.env.REACT_APP_PENDO_KEY_TEST}`;
    }

    this.apiUrl = url;
    this.cdnUrl = cdnUrl;
    this.pendoKey = pendoKey;
  }
}
