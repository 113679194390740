import React, { useEffect, useState, useMemo, useContext } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  AuthenticationService,
  ConfigurationService,
  CoverageService,
  AppSettingsService,
  CommonService,
} from "@services";
import PatientPANotificationPopup from "./PatientPANotificationPopup";
import Statement from "../CommonComponent/Statement";
import DynamicHtml from "../CommonComponent/DynamicHtml";
import SearchIcon from "@mui/icons-material/Search";
import PatientCardDetails from "./PatientCardDetails";
import Footer from "../CommonComponent/Footer";
import PatientSearchCoverage from "./PatientSearchCoverage";
import DebugMessage from "../CommonComponent/DebugMessage";
import { ConfigurationContext } from "../../../contexts/Configuration";
import { SearchContext } from "../../../contexts/Search";

const PatientCoverageFinder = ({ token, indicationid }) => {
  const defaultChannels = [
    "Show All",
    "Commercial",
    "Health Exchange",
    "Medicare",
    "Managed Medicaid",
    "State Medicaid",
  ];
  const { appliedConfiguration, setAppliedConfiguration } = useContext(ConfigurationContext);
  const { setSearchTermDisplay, benefitType, setBenefitType } = useContext(SearchContext);
  const [channels, setChannels] = useState(defaultChannels);
  const [clientProductConfigurations, setClientProductConfigurations] = useState(null);
  const [selectedBenefitCoverageCardSettings, setSelectedBenefitCoverageCardSettings] = useState([]);
  const [productName, setProductName] = useState("");
  const [customFooter, setCustomFooter] = useState("");
  const [message, setMessage] = useState("");
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [stylePath, setStylePath] = useState("");
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [plans, setPlans] = useState([]);
  const [allCoverages, setAllCoverages] = useState([]);
  const [selectedPlanCardDetails, setSelectedPlanCardDetails] = useState("");
  const [selectedPlanValue, setSelectedPlanValue] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [stateId, setStateId] = useState("");
  const [liveTypeId, setLiveTypeId] = useState("");
  const [statusDataField, setStatusDataField] = useState("");
  const [stDataField, setSTDataField] = useState("");
  const [paDataField, setPADataField] = useState("");
  const [header, setHeader] = useState("");
  const [dynamicStatement, setDynamicStatement] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [benefitHierarchyName, setBenefitHierarchyName] = useState("");
  const [selectedZipCode, setSelectedZipCode] = useState("");
  const [selectedInsuranceType, setInsuranceType] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [paButtonTitle, setPAButtonTitle] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [isPASTDetailPresent, setIsPASTDetailPresent] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showPlanSearch, setShowPlanSearch] = useState(true);
  const [isInsuranceChanged, setIsInsuranceChanged] = useState(false);
  const [isGeographicSearchChanged, setIsGeographicSearchChanged] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const pastPositiveValidValues = ["yes", "true", "1"];

  // Handler for Search button click
  const searchCoverageClick = async () => {
    setIsGeographicSearchChanged(false);
    setIsInsuranceChanged(false);
    setIsLoadingPage(true);
    setShowPlanSearch(false);
    setMessage("");
    setSelectedPlanValue("");
    setPlans([]);
    setAllCoverages([]);
    setSelectedPlanCardDetails("");
    setShowResults(false);
    setShowNotification(false);
    const config = clientProductConfigurations.configurations.find(
      (c) =>
        (c.coverageFinderConfiguration?.benefitType === benefitType ||
          c.coverageFinderConfiguration?.benefitType.toLowerCase().includes("both")) &&
        (!indicationid || c.indicationId.toString() === indicationid)
    );
    if (!config) {
      console.log("Config not found for " + benefitType + " benefit type and indication Id: " + indicationid);
    }
    const coverageRequest = {
      ProjectId: config.projectId,
      ZipCode: selectedZipCode,
      StateId: stateId,
      NPI: null,
      LiveTypeId: liveTypeId,
      ProductId: selectedProduct.ProductId,
      IndicationId: config.indicationId,
      resultName:
        benefitType.toLowerCase() === "pharmacy"
          ? config.coverageFinderConfiguration.pharmacyResultName
          : config.coverageFinderConfiguration.resultName,
    };
    const coverageResponse = await CoverageService.getInstance().getCoverage(coverageRequest);
    setStateId(coverageResponse?.stateId);
    if (coverageResponse != null && coverageResponse.coverages != null) {
      setAllCoverages(coverageResponse.coverages);
      let filteredData = coverageResponse.coverages.filter(
        (p) => p.Channel.toLowerCase().indexOf(selectedInsuranceType.toLowerCase()) > -1
      );
      if (filteredData != null && filteredData.length > 0) {
        setDynamicStatement(coverageResponse.statement);
        setPlans(filteredData);
      } else {
        setMessage("No results found");
      }
    }
    setShowPlanSearch(true);
    setIsLoadingPage(false);
  };

  useEffect(() => {
    let ignore = false;
    let tokenProducts = [];
    if (token) {
      const jwtPayload = CommonService.parseJwt(token);
      tokenProducts = JSON.parse(jwtPayload.products);
      setProductName(tokenProducts[0].Name);
      setSelectedProduct(tokenProducts[0]);
      const jwtWebsiteType = jwtPayload.websiteType ?? 2;
      const cdnUrl = AppSettingsService.getInstance().getCDNUrl();
      setStylePath(`${cdnUrl}css/${jwtPayload.clientId}_${tokenProducts[0].ProductId}_${jwtWebsiteType}.css`);
    } else {
      console.log("Token is null or empty");
    }

    async function getConfig() {
      const requestBody = {
        ProductId: tokenProducts[0].ProductId,
      };

      const configResponse = await ConfigurationService.getInstance().getConfiguration(requestBody);
      if (!ignore) {
        applyConfiguration(configResponse);
        setIsLoadingPage(false);
      }
    }

    if (token) {
      AuthenticationService.getInstance().setToken(token);
      getConfig();
    }

    return () => {
      ignore = true;
    };
  }, [token]);

  useMemo(() => {
    changeConfiguration(indicationid);
  }, [indicationid]);

  function changeConfiguration(indicationId) {
    if (clientProductConfigurations?.configurations) {
      if (indicationId) {
        let jwtWebsiteType = Number(CommonService.parseJwt(token).websiteType ?? 2);
        const config = clientProductConfigurations.configurations.find(
          (x) =>
            Number(x.indicationId) === Number(indicationid) &&
            x.coverageFinderConfiguration != null &&
            Number(x.coverageFinderConfiguration.websiteType) === jwtWebsiteType
        );
        if (config) {
          setAppliedConfiguration(config);
          setAllConfigValues(config);
        } else {
          console.log("no configuration found for indication");
        }
      } else {
        console.log("indicationId is null or empty");
      }
    }
  }

  function setAllConfigValues(config) {
    setCustomFooter(config.coverageFinderConfiguration?.footerText);
    setHeader(config.coverageFinderConfiguration?.header);
    setSubHeader(config.coverageFinderConfiguration?.subHeader);
    const benefitTypeId = config.coverageFinderConfiguration?.benefitType?.toLowerCase().includes("pharmacy") ? 2 : 1;
    setLiveTypeId(benefitTypeId == 2 ? 4 : 1)
    setBenefitHierarchyName(benefitTypeId == 2 ? config.coverageFinderConfiguration?.pharmacyResultName : config.coverageFinderConfiguration?.resultName);
    const paNotificationConfig = config.coverageFinderProjectAdditionalDetails?.find(
      (x) => Number(x.benefitTypeId) === Number(benefitTypeId)
    );
    if (paNotificationConfig) {
      setNotificationMessage(paNotificationConfig?.boxTitle);
      setPAButtonTitle(paNotificationConfig?.buttonTitle);
    }
    const patientCoverageDataFields = config.patientCoverageDataFields?.find(
      (x) => (x.isPharmacy == (benefitTypeId == 2))
    );
    if (patientCoverageDataFields) {
      setPADataField(patientCoverageDataFields?.paDataFieldName);
      setSTDataField(patientCoverageDataFields?.stDataFieldName);
      setStatusDataField(patientCoverageDataFields?.statusDataFieldName);
    }
    setSelectedBenefitCoverageCardSettings(config.coverageCardSettings?.filter(
      (x) => (x.isPharmacy == (benefitTypeId == 2))
    ));
    const cfSettings = config.settings;
    setChannels(config.projectChannels?.map((c) => c.channelName));
    if (config.indicationStylesheetURL) {
      setStylePath(config.indicationStylesheetURL);
    }

    if (cfSettings?.findIndex((setting) => setting.settingId === 81 && setting.isEnabled === true) > -1) {
      setDebugMode(true);
    }
  }

  const applyConfiguration = (configResponse) => {
    if (configResponse) {
      setClientProductConfigurations(configResponse);

      let jwtWebsiteType = Number(CommonService.parseJwt(token).websiteType ?? 2);
      let config = jwtWebsiteType
        ? configResponse.configurations.find(
          (x) =>
            x.coverageFinderConfiguration != null &&
            Number(x.coverageFinderConfiguration.websiteType) === jwtWebsiteType
        )
        : configResponse.configurations[0];

      if (indicationid) {
        config = configResponse.configurations.find(
          (x) =>
            Number(x.indicationId) === Number(indicationid) &&
            x.coverageFinderConfiguration != null &&
            Number(x.coverageFinderConfiguration.websiteType) === jwtWebsiteType
        );
      }
      setAppliedConfiguration(config);
      setBenefitType(config.coverageFinderConfiguration?.benefitType);
      setAllConfigValues(config);
    } else {
      console.log("Config response is null or empty");
    }
  };

  const handleZipSearch = (value) => {
    setIsGeographicSearchChanged(true);
    if (!value || value === "") 
      handleEmptySearch();
    else {      
      setSelectedZipCode(value);
      setSearchTermDisplay(value);
    }
  };

  const handleStateSearch = (value) => {
    setIsGeographicSearchChanged(true);
    if (!value || value === "") 
      handleEmptySearch();
    else {      
      setSearchTermDisplay(value);
      const searchState = appliedConfiguration?.states?.find((s) => s.name.toLowerCase() === value?.toLowerCase());
      setStateId(searchState?.stateId);
    }
  };

  function handleEmptySearch() {
    setSearchTermDisplay("");
    setSelectedZipCode("");
    setStateId("");
    setShowResults(false);
    setShowNotification(false);
    setSelectedPlanValue("");
    setPlans([]);
    setAllCoverages([]);
    setSelectedPlanCardDetails("");
  }

  function handleInvalidSearch() {
    setStateId("");
    setShowResults(false);
    setShowNotification(false);
    setSelectedPlanValue("");
    setPlans([]);
    setAllCoverages([]);
    setSelectedPlanCardDetails("");
  }

  const OnInsuranceChange = (newValue) => {
    setIsInsuranceChanged(true);
    setInsuranceType(newValue);
    setSelectedPlanValue("");
    setSelectedPlanCardDetails("");
    if (allCoverages != null && allCoverages.length > 0 && newValue) {
      let filteredData = allCoverages.filter(
        (p) => p.Channel.toLowerCase() == newValue.toLowerCase()
      );
      if (filteredData != null && filteredData.length > 0) {
        setPlans(filteredData);
      } else {
        setPlans([]);
      }
    } else {
      setPlans([]);
    }

    setShowResults(false);
    setShowNotification(false);
  };

  const reset = () => {
    setIsGeographicSearchChanged(false);
    setIsInsuranceChanged(false);
    setMessage("No results found");
    setSelectedPlanValue("");
    setSelectedPlanCardDetails("");
    setShowResults(false);
    setShowNotification(false);
    setIsLoadingPage(false);
  };

  const handlePlanInputChange = (newInputValue) => {
    (isGeographicSearchChanged || isInsuranceChanged) ? "" : setSelectedPlanValue(newInputValue)
  }

  const handlePlanSearch = (option) => {    
    setIsGeographicSearchChanged(false);
    setIsInsuranceChanged(false);
    if (!option) {
      reset();
      return;
    }
    setSelectedPlanValue(fetchOptionLabel(option));
    setSelectedPlan(option);
    setIsLoadingPage(true);
    if (selectedBenefitCoverageCardSettings != null && selectedBenefitCoverageCardSettings.length > 0) {
      let planDetails = selectedBenefitCoverageCardSettings.filter(
        (p) =>
          p.status.toLowerCase().split(",").includes(option[statusDataField]?.toLowerCase()) &&
          p.projectChannelName.toLowerCase().split(",").includes(option.Channel?.toLowerCase())
      );
      if (planDetails.length > 0) {
        let text = planDetails[0].valueText;
        text = text
          .replace("[benefitType]", benefitType)
          .replace(/\[benefit\]/g, benefitType)
          .replace("[productName]", appliedConfiguration?.brandName)
          .replace(/\[product\]/g, appliedConfiguration?.brandName);
        setSelectedPlanCardDetails(text);
        setShowResults(true);
        setShowNotification(true);
        setIsPASTDetailPresent(checkPlanForPASTDetail(option));
        setIsLoadingPage(false);
      } else {
        reset();
      }
    } else {
      reset();
    }
  };

  const checkPlanForPASTDetail = (selectedPlan) => {
    let isPAOrSTPresent = false;
    if (selectedPlan && appliedConfiguration?.coverageFinderConfiguration?.coverageFinderVersionId == 1) {
      const paFieldData = selectedPlan[paDataField];
      const stFieldData = selectedPlan[stDataField];
      isPAOrSTPresent = ((paFieldData && pastPositiveValidValues.includes(paFieldData.toLowerCase())) || (stFieldData && pastPositiveValidValues.includes(stFieldData.toLowerCase())))
    }
    return isPAOrSTPresent;
  }

  const fetchOptionLabel = (option) => {
    switch (benefitHierarchyName?.toLowerCase()) {
      case "controller/payer level":
        return option?.ControllerName;
      case "medical group level":
        return option?.MedicalGroupName;
      case "formulary level":
        return option?.FormularyName;
      default:
        return option?.PlanName;
    }
  }

  return (
    <>
      <link type="text/css" rel="stylesheet" href={stylePath} />
      {
        <Box className="norstella-cf-coverageFinderBox" sx={{ flexGrow: 1 }} part="cfCoverageFinderBox">
          {isLoadingPage && (
            <>
              <div className="norstella-cf-overlay">
                <Grid item className="norstella-cf-loaderTable">
                  <CircularProgress />
                </Grid>
              </div>
            </>
          )}
          {header && header.trim() !== "" && (
            <Grid item className="norstella-cf-GridItemHeader">
              <DynamicHtml
                input={header != null ? header : ""}
                className="norstella-cf-header"
              />
            </Grid>
          )}
          <Grid container spacing={2} direction={"column"} alignItems={"left"} className="norstella-cf-GridContainer">
            <PatientSearchCoverage
              channels={channels}
              OnInsuranceChange={OnInsuranceChange}
              handleZipSearch={handleZipSearch}
              handleStateSearch ={handleStateSearch}
              handleInvalidSearch = {handleInvalidSearch}
              searchCoverageClick={searchCoverageClick}
              appliedConfiguration = {appliedConfiguration}
            />
          </Grid>
          {subHeader && subHeader.trim() !== "" && (
            <Grid item className="norstella-cf-GridItemSubHeader">
              {/* SubHeader */}
              <DynamicHtml
                input={subHeader != null ? subHeader : ""}
                className="norstella-cf-headerSubHeader"
              />
            </Grid>
          )}
          <Grid item className="norstella-cf-GridItemStatement">
            <Statement dynamicStatement={dynamicStatement} />
          </Grid>
          {showPlanSearch && (<Grid item className="norstella-cf-patientDiv norstella-cf-GridItemSearchNames norstella-cf-topMargin">
            <Autocomplete
              id="planSelect"
              className="norstella-cf-searchText"
              part="cfSearchText"
              selectOnFocus
              handleHomeEndKeys
              disablePortal
              options={plans}
              getOptionLabel={fetchOptionLabel}
              onChange={(_, newValue) => handlePlanSearch(newValue)}
              inputValue={selectedPlanValue}
              onInputChange={(_, newInputValue) => handlePlanInputChange(newInputValue)}
              style={{ width: "100%" }}
              noOptionsText={<span style={{ color: 'red', fontWeight: 'bold' }}>No options</span>}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Plan Names"
                  className="norstella-cf-txtSearchNames"
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <SearchIcon className="norstella-cf-patientSearchIcon" />
                        {params.InputProps.startAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>)}
          {showNotification && isPASTDetailPresent ?
            <Grid item className="norstella-cf-patientDiv norstella-cf-GridItemSearchNames norstella-cf-topMargin">
              <PatientPANotificationPopup
              selectedPlan={selectedPlan}
              stateId={stateId}
              notificationMessage={notificationMessage} 
              buttonText={paButtonTitle}
              />
            </Grid>
            : ""}
          <Grid item className="norstella-cf-patientDiv norstella-cf-GridItemPatientCardDetails ">
            {showResults ? <PatientCardDetails selectedPlanCardDetails={selectedPlanCardDetails} config={clientProductConfigurations} />
              : <div style={{ margin: "auto",position:"relative" }} className="norstella-cf-message" part="cfMessage">
                {message}
              </div>}
          </Grid>
          <Grid item className="norstella-cf-GridItemFooter norstella-cf-topMargin">
            <Footer customFooter={customFooter} />
          </Grid>
          {debugMode && (
            <Grid item>
              <DebugMessage />
            </Grid>
          )}
        </Box>
      }
    </>
  );
};

export default PatientCoverageFinder;


