import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import useMediaQuery from "@mui/material/useMediaQuery";
import NativeSelect from "@mui/material/NativeSelect";
import IconButton from "@mui/material/IconButton";
import { PendoService } from "@services";

const FilterCoverage = ({ channels, planSearch, channelFilter }) => {
  const [channel, setChannel] = useState(channels[0]);
  const [searchValue, setSearchValue] = useState("");
  const channelsObjectArray = channels.map((chn) => ({
    id: chn,
    label: chn,
  }));
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const [typingTimer, setTypingTimer] = useState(null);
  const doneTypingInterval = 3000; //3 seconds

  const handleChannel = (event) => {
    if (event.target.value) {
      //pendo track event
      PendoService.getInstance().trackEvent("handleChannel Event", "handleChannel", event.target.value);

      setChannel(event.target.value);
      channelFilter(event.target.value);
    }
  };

  const handlePlanSearch = (event) => {
    setSearchValue(event.target.value);

    //pendo track event
    if (event.target.value !== "") {
      clearTimeout(typingTimer);
      setTypingTimer(setTimeout(function() {
        PendoService.getInstance().trackEvent("handlePlanSearch Event", "handlePlanSearch", event.target.value);
      }, doneTypingInterval));
    }
    else {
      clearTimeout(typingTimer);
    }

    planSearch(event.target.value);
  };

  const handleClearPlanSearch = () => {
    setSearchValue("");
    planSearch("");
  };

  return (
    <div className="norstella-cf-flexAlignFilterBar" part="cfDivFilter">
      {matchesMobileWidth && channelsObjectArray.length > 0 && (
        <>
          <label className="norstella-cf-channelLabel" part="cfChannelLabel">
            Filter By
          </label>
          <div className="norstella-cf-channelDiv">
            <NativeSelect
              onChange={handleChannel}
              defaultValue={channel}
              disableUnderline
              inputProps={{
                name: "ddlChannel",
                id: "ddlChannel",
              }}
              part="cfDdlChannel"
              className="norstella-cf-filterCoverageNativeSelect"
            >
              {channelsObjectArray.map((channel) => (
                <option key={channel.id} value={channel.id}>
                  {channel.label}
                </option>
              ))}
            </NativeSelect>
          </div>
        </>
      )}
      <TextField
        id="txtPlanSearch"
        placeholder="Search"
        className="norstella-cf-searchPlans norstella-cf-bottomMargin"
        part="cfTxtPlanSearch"
        variant="outlined"
        onChange={handlePlanSearch}
        value={searchValue}
        InputProps={{
          className: "norstella-cf-searchPlansInput",
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className="norstella-cf-filterCoverageSearchIcon" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchValue && (
                <IconButton
                  className="norstella-cf-filterCoverageClearIconButton"
                  aria-label="clear plan search text"
                  onClick={handleClearPlanSearch}
                >
                  <ClearIcon className="norstella-cf-filterCoverageClearIcon" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
      {!matchesMobileWidth && channelsObjectArray.length > 0 && (
        <div className="norstella-cf-divChannelButtonGroup">
          <ToggleButtonGroup
            exclusive
            value={channel}
            onChange={handleChannel}
            className="norstella-cf-channelButtonGroup"
            part="cfChannelButtonGroup"
          >
            {channels.map((chn) => (
              <ToggleButton key={chn} value={chn} className="norstella-cf-channelButton" part="cfChannelButton">
                {chn}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
      )}
    </div>
  );
};

export default FilterCoverage;
