import React, { useRef, useEffect, useContext } from "react";
import { CommonService ,PendoService} from "@services";
import { ConfigurationContext } from "../../../contexts/Configuration";
import { SearchContext } from "../../../contexts/Search";

const DynamicHtml = ({
  input,
  resultname,
  className,
  part,
  type,
}) => {
  const { appliedConfiguration } = useContext(ConfigurationContext);
  const { searchTermDisplay, benefitType } = useContext(SearchContext);

  input = String(input);
  if (typeof part === "undefined" || part === "") {
    part = "cfDivHeaderText";
  }
  input = CommonService.htmlDecode(input ?? "");
  input = input
    .replace("[benefitType]", benefitType)
    .replace(/\[benefit\]/g, benefitType)
    .replace("[product] Coverage Details", "<span class='norstella-cf-divDrug'>[product] Coverage Details</span>")
    .replace("[product] COVERAGE DETAILS", "<span class='norstella-cf-divDrug'>[product] COVERAGE DETAILS</span>")
    .replace(/\[product\]/g, "<span class='norstella-cf-divDrug'>" + appliedConfiguration?.brandName + "</span>")
    .replace(/\[result\]/g, "<span class='norstella-cf-result'>" + resultname + "</span>")
    .replace(
      /\[indication\]/g,
      "<span class='norstella-cf-indication'>" + appliedConfiguration?.indicationName + "</span>"
    )
    .replace(/\[geography\]/g, "<span class='norstella-cf-geography'>" + searchTermDisplay + "</span>");
  if (input != null) {
    input = CommonService.replaceDatePlaceholders(input);
  }
  const ref = useRef(null); 
  useEffect(() => {
    const handleClick = (event) => {
      const target = event.target;
      if (target.tagName === 'A') {
        const title=target.textContent;
        //pendo track event
         PendoService.getInstance().trackEvent(title+" Url" , title.includes("PA Form")?"prescriberNotes/openPAForms":"prescriberNotes/openPolicyLink", target.href);
        // Open the link in a new tab
        window.open(target.href, '_blank', 'noopener,noreferrer');
        event.preventDefault(); // Prevent default if you are handling the opening
      }
    };

    const node = ref.current;
    if (node) {
      node.addEventListener('click', handleClick);
    }

    return () => {
      if (node) {
        node.removeEventListener('click', handleClick);
      }
    };
  }, []);

  let isUrl = input.startsWith("https://") || input.startsWith("http://");

  if (isUrl) {
    return (
      <div>
        <a className="norstella-cf-href" target="_blank" rel="noreferrer" href={input} ref={ref}>
          PA & Step Therapy Comprehensive Policy-1
        </a>
      </div>
    );
  }
  if (input && input.trim() !== "") {
    switch (type) {
      case "label":
        return <label className={className} part={part} dangerouslySetInnerHTML={{ __html: input }} ref={ref} />;
      default:
        // Default case if type doesn't match any specific control
        return <div className={className} part={part} dangerouslySetInnerHTML={{ __html: input }} ref={ref} />;
    }
  }
};

export default DynamicHtml;
