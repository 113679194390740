import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { PendoService } from "@services";
import {InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const PatientSearchCoverage = ({ channels, OnInsuranceChange, handleZipSearch, handleStateSearch, handleInvalidSearch, searchCoverageClick, appliedConfiguration }) => {
  const [selectedInsuranceType, setInsuranceType] = useState(null);
  const [selectedZipCode, setSelectedZipCode] = useState("");
  const [selectedTerritory, setSelectedTerritory] = useState("");
  const [insuranceTypeErrorMessage, setInsuranceTypeErrorMessage] = useState("");
  const [geographyErrorMessage, setGeographyErrorMessage] = useState("");
  const [geographySearchTitle, setGeographySearchTitle] = useState("");  
  const [zipCodeSearchOnly, setZipCodeSearchOnly] = useState(false);
  const [stateSearchOnly, setStateSearchOnly] = useState(false);
  const [states, setStates] = useState([]);
  const usZipCodeRegex = new RegExp(/^\d{5}(-\d{4})?(?!-)$/);

  useEffect( () => {    
    const cfConfig = appliedConfiguration?.coverageFinderConfiguration;
    const isZipCodeSearchOnly = cfConfig?.geographyType?.toLowerCase() == "zip code only";
    const isStateSearchOnly = cfConfig?.geographyType?.toLowerCase() == "state only";
    
    setStateSearchOnly(isStateSearchOnly);
    setZipCodeSearchOnly(isZipCodeSearchOnly);
    setGeographySearchTitle(isZipCodeSearchOnly ? "ZIP Code:" : (isStateSearchOnly ? "State:" : "ZIP Code or State:" ));

    if (!isZipCodeSearchOnly) {
      let states = appliedConfiguration?.states?.sort((a, b) =>
        a.name.toUpperCase() < b.name.toUpperCase() ? -1 : a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0
      );
      setStates(states);
    }
  }, [appliedConfiguration])

  const OnInsuranceValueChange = (newValue, trackInPendo = true) => {
    if(trackInPendo) {      
      PendoService.getInstance().trackEvent(
        "Patient Website > OnInsuranceValue Change",
        "OnInsuranceValueChange",
        newValue
      );
    }
    setInsuranceType(newValue);
    OnInsuranceChange(newValue);
  };

  const handleClear = () => {
    setSelectedZipCode("");
    setSelectedTerritory("");
    setGeographyErrorMessage("");
    handleZipSearch('', false);
  };

  const handleZipCodeSearch = (value, trackInPendo = true) => {
    if(trackInPendo) {      
      PendoService.getInstance().trackEvent(
        "Patient Website > handleZipCode Search",
        "handleZipCodeSearch",
        value
      );
    }
    setSelectedZipCode(value ?? "");
    setSelectedTerritory(value?? "");
    handleZipSearch(value ?? "");
  };

  const handleSelectedTerritory = (value, trackInPendo = true) => {    
    if (value?.trim() !== "" && usZipCodeRegex.test(value)) 
      handleZipCodeSearch(value, trackInPendo);
    else {
      if(trackInPendo) {      
        PendoService.getInstance().trackEvent(
          "Patient Website > handleSelectedTerritory Search",
          "handleSelectedTerritory",
          value
        );
      };    
      setSelectedTerritory(value ?? "");
      handleStateSearch(value ?? "");
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode == 13 && event.target.value) {
      PendoService.getInstance().trackEvent(
        "Patient Website > handleKeyDown Event",
        "handleKeyDown",
        event?.target?.value
      );
      handleSelectedTerritory(event?.target?.value);
      triggerCoverageSearch();
      if (!isNaN(Number(event.target.value[0]))) {
        event.stopPropagation();
      }
    }
  };

  const triggerCoverageSearch = () => {
    const searchCriteriaValidityStatus = getSearchCriteriaValidityStatus();

    if (searchCriteriaValidityStatus && searchCriteriaValidityStatus.isSearchCriteriaValid) {
      setInsuranceTypeErrorMessage("");
      setGeographyErrorMessage("");
      searchCoverageClick();
    }
    else {
      searchCriteriaValidityStatus?.isInsuranceTypeValid ? "" : setInsuranceTypeErrorMessage("Please select valid Insurance Type.");
      let geographicErrorMessage = "Please enter a ";
      geographicErrorMessage +=  zipCodeSearchOnly
      ? ( 
          searchCriteriaValidityStatus?.isZipCodeValid ? "" : "valid ZIP Code."
        )
      : ( 
          searchCriteriaValidityStatus?.isTerritoryValid 
            ? "" 
            : (
                stateSearchOnly 
                ? "valid State."
                : "valid ZIP Code or State."
              )
        )
      setGeographyErrorMessage(geographicErrorMessage);
      handleInvalidSearch();
    }
  }

  const getSearchCriteriaValidityStatus = () => {
    const isZipCodeValid = (zipCodeSearchOnly && selectedZipCode && selectedZipCode.trim() !== "" && usZipCodeRegex.test(selectedZipCode));
    const isTerritoryValid = stateSearchOnly ? selectedTerritory && states?.find((st) => st.name.toLowerCase() === selectedTerritory?.toLowerCase())
                        : selectedTerritory && (states?.find((st) => st.name.toLowerCase() === selectedTerritory?.toLowerCase()) || (selectedTerritory.trim() !== "" && usZipCodeRegex.test(selectedTerritory)));
    return {
      isSearchCriteriaValid: selectedInsuranceType && (isZipCodeValid || isTerritoryValid),
      isInsuranceTypeValid: selectedInsuranceType,
      isZipCodeValid: isZipCodeValid,
      isTerritoryValid : isTerritoryValid
    }
  }

  return (
    <div style={{ padding: "20px", marginTop: "20px", width: "100%" }} className="norstella-cf-SearchCoverage">
      {/* First row */}
      <div className="norstella-cf-patientDiv">
        <div style={{ width: "30%" }} className="norstella-cf-InsuranceType">
         Insurance Type:
        </div>
        <div style={{ width: "30%" }} className="norstella-cf-ZipCode">
          {geographySearchTitle}
        </div>
      </div>

      {/* Second row */}
      <div className="norstella-cf-patientDiv norstella-cf-secondRow norstella-cf-bottomMargin">
        <div style={{ fontWeight: "bold", width: "30%" }} className="norstella-cf-divSearchText">
          <Autocomplete
            id="insuranceSelect"
            className="norstella-cf-searchText"
            part="cfSearchText"
            options={channels}
            value={selectedInsuranceType}
            blurOnSelect
            handleHomeEndKeys
            disableClearable
            disablePortal
            onChange={(event, newValue) => OnInsuranceValueChange(newValue)}
            style={{ width: "90%" }}
            renderInput={(params) => <TextField {...params} className="norstella-cf-txtSearchText" />}
          />
        </div>
        <div className="norstella-cf-patientGeoSearchDiv" style={{ fontWeight: "bold", width: "30%" }}>
          {(zipCodeSearchOnly 
            ? (<TextField
              id="geoSearch"
              placeholder="Enter ZIP Code"
              className="norstella-cf-patientGeoSearch"
              disabled={!selectedInsuranceType}
              style={{ width: "100%" }}
              part="cfGeoSearch"
              onChange={(event) => handleZipCodeSearch(event?.target?.value)}
              onKeyDown={handleKeyDown}
              value={selectedZipCode}
              InputProps={{
                endAdornment: selectedZipCode ? (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClear} size="small">
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />)
            : (<Autocomplete
                disablePortal
                className="norstella-cf-searchText"
                freeSolo
                blurOnSelect
                handleHomeEndKeys
                id="geoSearch"
                disabled={!selectedInsuranceType}
                value={selectedTerritory}
                options={states}
                onInputChange={(_, value) => handleSelectedTerritory(value)}
                placeholder={stateSearchOnly ? "Select State" : "Enter ZIP Code or State"}
                onChange={(_, value) => {
                  if (!value) 
                    handleClear();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onKeyDown={handleKeyDown}
                    placeholder={stateSearchOnly ? "Select State" : "Enter ZIP Code or State"}
                  />
                )}
                getOptionLabel={(option) => option.name ?? option}
                style={{ width: "100%" }}
                part="cfGeoSearch"
              />))}
        </div>
        <div className="norstella-cf-patientZipSearchDiv" style={{ fontWeight: "bold", width: "40%" }}>
          <Button
            disabled={!(selectedZipCode || selectedTerritory)}
            variant="contained"
            style={{ marginLeft: "10px" }}
            className="norstella-cf-searchButton"
            id="searchButton"
            onClick={triggerCoverageSearch}
            part="cfSearchButton"
          >
            Search
          </Button>
        </div>
      </div>

      {/* Third Row */}
      {((insuranceTypeErrorMessage && insuranceTypeErrorMessage.trim() !== "") || (geographyErrorMessage && geographyErrorMessage.trim() !== ""))
        ? <div className="norstella-cf-patientDiv norstella-cf-thirdRow norstella-cf-bottomMargin">
          <div style={{ width: "30%", color: "red" }} part="cfInsuranceTypeErrorMessage">
            {insuranceTypeErrorMessage}
          </div>
          <div style={{ width: "30%", color: "red" }} part="cfGeographyErrorMessage">
            {geographyErrorMessage}
          </div>
        </div>
        : ""}
    </div>
  );
};

export default PatientSearchCoverage;
