import React from "react";
import ReactDOM from "react-dom/client";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import CoverageFinder from "./components/CoverageFinder";
import Store from "./Store";

const getStylesAsString = () => {
  let rules = [];

  for (let i = 0; i < document.styleSheets.length; i++) {
    let foundCFStyleSheet = document.styleSheets[i]?.ownerNode?.innerHTML
      ?.toLowerCase()
      ?.includes("norstellacoveragefinder");

    if (!foundCFStyleSheet) {
      foundCFStyleSheet = document.styleSheets[i]?.title?.toLowerCase() == "norstellacoveragefinder";
    }

    if (foundCFStyleSheet) {
      for (let j = 0; j < document.styleSheets[i].cssRules.length; j++) {
        rules.push(document.styleSheets[i].cssRules[j].cssText);
      }
    }
  }

  const css = rules.join("\n");
  return css;
};

class CoverageFinderWebComponent extends HTMLElement {
  static observedAttributes = ["indication", "token", "zipCode"];

  root;
  token;
  indicationId;
  environment;
  cssCache;
  zipCode;

  renderCoverageFinder() {
    if (this.root) {
      this.root.render(
        <React.StrictMode>
          <Store>
            <CacheProvider value={this.cssCache}>
              <CoverageFinder
                token={this.token}
                indicationid={this.indicationId}
                environment={this.environment}
                zipCode={this.zipCode}
              />
            </CacheProvider>
          </Store>
        </React.StrictMode>
      );
    }
  }

  connectedCallback() {
    const mountPoint = document.createElement("span");
    const shadowContainer = this.attachShadow({ mode: "open" });

    const emotionRoot = document.createElement("style");
    shadowContainer.appendChild(emotionRoot);
    shadowContainer.appendChild(mountPoint);

    const cssCache = createCache({
      key: "css",
      prepend: true,
      container: emotionRoot,
    });
    this.cssCache = cssCache;

    const cfCSSRules = getStylesAsString();

    if (shadowContainer.adoptedStyleSheets) {
      const sheet = new CSSStyleSheet();
      sheet.replaceSync(cfCSSRules);
      shadowContainer.adoptedStyleSheets = [sheet];
    } else {
      let styleElement = document.createElement("style");
      styleElement.textContent = cfCSSRules;
      shadowContainer.appendChild(styleElement);
    }

    this.token = this.getAttribute("token");
    this.indicationId = this.getAttribute("indication");
    this.environment = this.getAttribute("environment");
    this.zipCode = this.getAttribute("zipCode");
    this.root = ReactDOM.createRoot(mountPoint);

    this.renderCoverageFinder();
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (name === "indication" && newValue) {
      this.indicationId = newValue;
    } else if (name === "token" && newValue) {
      this.token = newValue;
    } else if (name === "zipCode" && newValue) {
      this.zipCode = newValue;
    }

    if (newValue) {
      this.renderCoverageFinder();
    }
  }
}
export default CoverageFinderWebComponent;

window.customElements.get("coverage-finder") ||
  window.customElements.define("coverage-finder", CoverageFinderWebComponent);
