import axios from "axios";
import { AuthenticationService, AppSettingsService } from "@services";

export default class CoverageService {
  static classInstance = null;
  static getInstance() {
    if (CoverageService.classInstance === null) {
      CoverageService.classInstance = new CoverageService();
    }
    return this.classInstance;
  }

  async getCoverage(coverageRequest) {
    const apiUrl = AppSettingsService.getInstance().getApiUrl();
    const url = `${apiUrl}coverage`;
    const headers = await AuthenticationService.getInstance().getHeaders();
    const coverage = await axios.post(`${url}`, coverageRequest, headers).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error(error);
      }
    );
    return coverage;
  }
  async getPrescriberNotes(prescriberNoteRequest) {
    const apiUrl = AppSettingsService.getInstance().getApiUrl();
    const url = `${apiUrl}coverage/PrescriberNotes`;
    const headers = await AuthenticationService.getInstance().getHeaders();
    const prescriberNoteResponse = await axios.post(`${url}`, prescriberNoteRequest, headers).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error(error);
      }
    );
    return prescriberNoteResponse;
  }
  async getDocumentLinks(documentLinkRequest) {
    const apiUrl = AppSettingsService.getInstance().getApiUrl();
    const url = `${apiUrl}coverage/DocumentLinks`;
    const headers = await AuthenticationService.getInstance().getHeaders();
    const documentLinkResponse = await axios.post(`${url}`, documentLinkRequest, headers).then(
      (response) => {
        return response.data;
      },
      (error) => {
        console.error(error);
      }
    );
    return documentLinkResponse;
  }
}
