import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import FilterCoverage from "./FilterCoverage";
import SearchCoverage from "./SearchCoverage";
import Statement from "../CommonComponent/Statement";
import DisplayCoverage from "./DisplayCoverage";
import Footer from "../CommonComponent/Footer";
import {
  AuthenticationService,
  ConfigurationService,
  CoverageService,
  AppSettingsService,
  CommonService,
  StyleService,
  PrescriberNoteService,
  PendoService,
} from "@services";
import DynamicHtml from "../CommonComponent/DynamicHtml";
import BenefitTypeButton from "./BenefitTypeButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import StateMap from "../CommonComponent/StateMap";
import DebugMessage from "../CommonComponent/DebugMessage";
import { ConfigurationContext } from "../../../contexts/Configuration";
import { SearchContext } from "../../../contexts/Search";

const HCPCoverageFinder = ({ token, indicationid, zipCode }) => {
  const defaultChannels = [
    "Show All",
    "Commercial",
    "Health Exchange",
    "Medicare",
    "Managed Medicaid",
    "State Medicaid",
  ];
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const { appliedConfiguration, setAppliedConfiguration } = useContext(ConfigurationContext);
  const { searchTermDisplay, setSearchTermDisplay, benefitType, setBenefitType } = useContext(SearchContext);
  const [showResults, setShowResults] = useState(false);
  const [selectedBenefitType, setSelectedBenefitType] = useState("");
  const [benefitTypes, setBenefitTypes] = useState([]);
  const [showBenefitType, setShowBenefitType] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [disableBenefitType, setDisableBenefitType] = useState(true);
  const [searchNPI, setNPI] = useState("");
  const [selectedIndication, setSelectedIndication] = useState(null);
  const [gridColumnsBothBenefits, setGridColumnsBothBenefits] = useState([]);
  const [dynamicColumns, setDynamicColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [channels, setChannels] = useState(defaultChannels);
  const [clientProductConfigurations, setClientProductConfigurations] = useState(null);
  const [customFooter, setCustomFooter] = useState("");
  const [settings, setSettings] = useState(null);
  const [resultMessage, setResultMessage] = useState("");
  const [isLoadingCoverage, setIsLoadingCoverage] = useState(false);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [stylePath, setStylePath] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [planNameFilter, setPlanNameFilter] = useState("");
  const [channelFilter, setChannelFilter] = useState("Show All");
  const [dynamicStatement, setDynamicStatement] = useState("");
  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [websiteType, setWebsiteType] = useState("1");
  const [selectedState, setSelectedState] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [stateId, setStateId] = useState("");
  const coverageTableContainerRef = useRef();
  const [visibleRows, setvisibleRows] = useState([]);
  const [debugMode, setDebugMode] = useState(false);
  const [noDataWarning, setNoDataWarning] = useState("");
  const [filterValue, setFilterValues] = useState([]);
  const [showDataSource, setShowDataSource] = useState(false);
  const [isFilterSame, setIsFilterSame] = useState(true);
  let tokenProducts = [];
  useEffect(() => {
    let ignore = false;
    if (token) {
      const jwtPayload = CommonService.parseJwt(token);
      tokenProducts = JSON.parse(jwtPayload.products);
      setProducts(tokenProducts);
      const jwtWebsiteType = jwtPayload.websiteType ?? 1;
      setWebsiteType(jwtWebsiteType);
      const cdnUrl = AppSettingsService.getInstance().getCDNUrl();
      if (tokenProducts.length === 1) {
        setStylePath(`${cdnUrl}css/${jwtPayload.clientId}_${tokenProducts[0].ProductId}_${jwtWebsiteType}.css`);
      }
    } else {
      console.log("Token is null or empty");
    }

    async function getConfig() {
      const requestBody = {
        ProductId: tokenProducts[0].ProductId,
      };

      const configResponse = await ConfigurationService.getInstance().getConfiguration(requestBody);
      if (!ignore) {
        applyConfiguration(configResponse);
        setIsLoadingPage(false);
      }
    }

    if (token) {
      AuthenticationService.getInstance().setToken(token);
      getConfig();
    }

    return () => {
      ignore = true;
    };
  }, [token]);

  useMemo(() => {
    const newIndication = selectedIndication ?? indicationid;
    changeConfiguration(newIndication);
  }, [indicationid, selectedIndication, clientProductConfigurations]);

  useMemo(() => {
    filterCoverageTable(rows, benefitType);
  }, [planNameFilter, channelFilter]);

  useMemo(() => {
    updateVisbileRow();
  }, [filteredRows, order, orderBy]);
  useEffect(() => {
    if (isFilterSame && filterValue.length > 0) {
      handleChangeBenefitType(benefitType);
    }
  }, [isFilterSame, filterValue]);
  function updateVisbileRow() {
    setIsLoadingCoverage(true);
    if (order === "") {
      setvisibleRows(filteredRows);
    } else {
      let sortBy = orderBy === "PrescriberNotes" ? "IsPrescriberNotePresent" : orderBy;
      let data = stableSort(filteredRows, getComparator(order, sortBy));
      setvisibleRows(data);
    }
    setIsLoadingCoverage(false);
  }

  function getComparator(order, orderBy) {
    const enCollator = new Intl.Collator("en");
    return order === "desc"
      ? (a, b) => -enCollator.compare(a[orderBy], b[orderBy])
      : (a, b) => enCollator.compare(a[orderBy], b[orderBy]);
  }

  function stableSort(array, comparator) {
    return array.slice().sort(comparator);
  }

  function changeConfiguration(newIndicationId) {
    if (clientProductConfigurations?.configurations) {
      if (newIndicationId && appliedConfiguration?.indicationId != newIndicationId) {
        let jwtWebsiteType = Number(CommonService.parseJwt(token).websiteType ?? 1);
        const config = clientProductConfigurations.configurations.find(
          (x) =>
            Number(x.indicationId) === Number(newIndicationId) &&
            x.coverageFinderConfiguration != null &&
            Number(x.coverageFinderConfiguration.websiteType) === jwtWebsiteType
        );
        if (config) {
          setAppliedConfiguration(config);
          setAllConfigValues(config);
        } else {
          console.log("no configuration found for indication");
        }
      } else {
        console.log("indicationId is null or empty");
      }
      setShowBenefitType(false);
    }
  }
  function filterData(data, query, columnsList) {
    return data.filter((item) => {
      // Check if any specified column matches the query
      return columnsList.some((column) => {
        let columnValue = item[column.dataField];
        return typeof columnValue === "string" && columnValue.toLowerCase().includes(query.toLowerCase());
      });
    });
  }
  function getResultName(name) {
    const lowercaseName = name ? name.toLowerCase() : "";
    switch (lowercaseName) {
      case "controller/payer level":
        return "payers";
      case "medical group level":
        return "medical groups";
      case "formulary level":
        return "formulary groups";
      default:
        return "plans";
    }
  }
  function filterCoverageTable(rowsData, newBenefitType) {
    const benefitTypeId = newBenefitType.toLowerCase().includes("pharmacy") ? 2 : 1;
    let filterGridColumns = gridColumnsBothBenefits.filter((p) => p.benefitTypeId === benefitTypeId);
    let filteredData = rowsData;
    let filterDynamicColumn = filterGridColumns;
    //FTW - SettingId 78: CF - Hide Plan Type Column When a Channel is Selected
    if (channelFilter === "Show All") {
      if (settings?.findIndex((setting) => setting.settingId === 78 && setting.isEnabled === true) > -1) {
        filterDynamicColumn = filterGridColumns;
        if (filterValue.length > 0) {
          filterGridColumns = getFilterGridColumns(benefitTypeId, filterGridColumns);
        }
        setDynamicColumns(filterGridColumns);
      }
      filteredData = rowsData;
    } else {
      if (settings?.findIndex((setting) => setting.settingId === 78 && setting.isEnabled === true) > -1) {
        filterDynamicColumn = filterGridColumns.filter((cell) => cell.dataField !== "Channel");
        if (filterValue.length > 0) {
          filterDynamicColumn = getFilterGridColumns(benefitTypeId, filterDynamicColumn);
        }
        setDynamicColumns(filterDynamicColumn);
      }
      filteredData = rowsData.filter((p) => p.Channel === channelFilter);
    }
    if (planNameFilter) {
      filteredData = filterData(filteredData, planNameFilter, filterDynamicColumn);
    }
    setFilteredRows(filteredData);
    if (appliedConfiguration?.coverageFinderConfiguration) {
      const resultName = getResultName(
        benefitTypeId == 1
          ? appliedConfiguration.coverageFinderConfiguration.resultName
          : appliedConfiguration.coverageFinderConfiguration.pharmacyResultName
      );
      const noDataMessage =
        filteredData.length < 1 && rowsData.length > 0
          ? `There are no ${resultName} with this plan type in this area.`
          : "";

      setNoDataWarning(noDataMessage);
    }
    if (coverageTableContainerRef?.current) {
      coverageTableContainerRef.current.scrollTo(0, 0);
    }
  }

  function setAllConfigValues(config) {
    setCustomFooter(config.coverageFinderConfiguration?.footerText);
    setHeader(config.coverageFinderConfiguration?.header);
    setSubHeader(config.coverageFinderConfiguration?.subHeader);
    setGridColumnsBothBenefits(config.coverageFinderGridColumns);
    const cfSettings = config.settings;
    setSettings(cfSettings);
    if (config.indicationStylesheetURL) {
      setStylePath(config.indicationStylesheetURL);
    }
    if (
      cfSettings?.findIndex((setting) => setting.settingId === 83 && setting.isEnabled === true) > -1 &&
      tokenProducts.length > 0
    ) {
      let sortProduct = tokenProducts.sort((a, b) => (a.Name > b.Name ? 1 : b.Name > a.Name ? -1 : 0));
      let productList =
        sortProduct.length > 1 ? sortProduct.map((product) => product.ProductId).join("-") : sortProduct[0].ProductId;
      const cdnUrl = AppSettingsService.getInstance().getCDNUrl();
      setStylePath(`${cdnUrl}css/${config?.clientId}_${productList}_${websiteType}.css`);
      StyleService.getInstance().loadCustomFonts(config?.clientId, cdnUrl, productList);
      if (config.ProductStylesheetURL) {
        setStylePath(config.ProductStylesheetURL);
      }
    }
    if (cfSettings?.findIndex((setting) => setting.settingId === 79 && setting.isEnabled === true) > -1) {
      setShowMap(true);
    }

    if (cfSettings?.findIndex((setting) => setting.settingId === 81 && setting.isEnabled === true) > -1) {
      setDebugMode(true);
    }

    if (cfSettings?.findIndex((setting) => setting.settingId === 85 && setting.isEnabled === true) > -1) {
      setShowDataSource(true);
    }
    PendoService.getInstance().updateMetadata(config.indicationName);
  }

  function matchesCriteria(record, criteriaObj) {
    // Split coverageFinderColumnTitles into an array of column titles
    var columns = criteriaObj.coverageFinderColumnTitles.split(",");
    // Check if DataField of the record exists in the columns array
    return columns.includes(record.dataField);
  }

  function getFilterGridColumns(benefitTypeId, filterGridColumns) {
    let filters = appliedConfiguration.coverageFinderFilters?.find(
      (x) => x.benefitTypeId == benefitTypeId && x.enabled
    );
    const valueSelected = filters.filterCriteria.filter((criteria) =>
      filterValue.some((selected) => selected.filterCriteria === criteria.filterCriteria)
    );
    let filtercolumn = filterGridColumns.filter((record) =>
      // Check if there is any criteria in data2 where the record matches
      valueSelected.some((criteriaObj) => matchesCriteria(record, criteriaObj))
    );
    let filterGridDataFieldColumns = filters?.filterCriteria.map((item) => item.coverageFinderColumnTitles);
    if (filterGridDataFieldColumns) {
      let staticColumns = filterGridColumns.filter((record) => {
        let includeStaticColumn = true;
        for (let i = 0; i < filterGridDataFieldColumns.length; i++) {
          let columnDataFields = filterGridDataFieldColumns[i].split(",");
          let includedInFiterColumns = columnDataFields.includes(record.dataField);
          if (includedInFiterColumns) {
            includeStaticColumn = false;
            break;
          }
        }
        return includeStaticColumn;
      });
      filterGridColumns = [...staticColumns, ...filtercolumn];
    }

    return filterGridColumns;
  }

  const applyConfiguration = (configResponse) => {
    if (configResponse) {
      setClientProductConfigurations(configResponse);

      let jwtWebsiteType = Number(CommonService.parseJwt(token).websiteType ?? 1);
      let config = jwtWebsiteType
        ? configResponse.configurations.find(
            (x) =>
              x.coverageFinderConfiguration != null &&
              Number(x.coverageFinderConfiguration.websiteType) === jwtWebsiteType
          )
        : configResponse.configurations[0];

      if (indicationid) {
        config = configResponse.configurations.find(
          (x) =>
            Number(x.indicationId) === Number(indicationid) &&
            x.coverageFinderConfiguration != null &&
            Number(x.coverageFinderConfiguration.websiteType) === jwtWebsiteType
        );
      }

      setAppliedConfiguration(config);
      if (config.coverageFinderConfiguration.benefitType.toLowerCase() === "both-medical(default)") {
        setBenefitTypes(["Medical", "Pharmacy"]);
        setSelectedBenefitType("Medical");
        setBenefitType("Medical");
      } else if (config.coverageFinderConfiguration.benefitType.toLowerCase() === "both-pharmacy(default)") {
        setBenefitTypes(["Pharmacy", "Medical"]);
        setSelectedBenefitType("Pharmacy");
        setBenefitType("Pharmacy");
      } else {
        setBenefitTypes([config.coverageFinderConfiguration.benefitType]);
        setSelectedBenefitType(config.coverageFinderConfiguration.benefitType);
        setBenefitType(config.coverageFinderConfiguration.benefitType);
      }
      setAllConfigValues(config);
    } else {
      console.log("Config response is null or empty");
    }
  };

  const handleShowResults = (show) => {
    setShowResults(show);
    if (!show) {
      setRows([]);
      setFilteredRows([]);
      setOrderBy("");
      setResultMessage("");
    }
  };

  const handleRequestSort = (event, property) => {
    let sortOrder = "asc";
    if (orderBy === property && order === "asc") {
      sortOrder = "desc";
    } else if (orderBy === property && order === "desc") {
      sortOrder = "";
    }
    setOrder(sortOrder);
    setOrderBy(property);
  };
  const handleFilterChange = async (value, isFilterSame) => {
    setFilterValues(value);
    setIsFilterSame(isFilterSame);
  };
  const handleChangeBenefitType = async (newBenefitType) => {
    if (newBenefitType && !disableBenefitType) {
      setBenefitType(newBenefitType);
      setSelectedBenefitType(newBenefitType);
      const benefitTypeId = newBenefitType.toLowerCase().includes("pharmacy") ? 2 : 1;
      let filters = appliedConfiguration.coverageFinderFilters?.find(
        (x) => x.benefitTypeId == benefitTypeId && x.enabled
      );
      if (filters && !isFilterSame) {
        setResultMessage("");
        setIsLoadingCoverage(true);
        setIsLoadingCoverage(false);
      } else if (searchTermDisplay != "") {
        setShowResults(true);
        setIsLoadingCoverage(true);
        const coverageResponse = await coverageSearch(newBenefitType);
        setRows(coverageResponse?.coverages?.filter((obj) => obj.Channel !== ""));
        setFilteredRows(coverageResponse?.coverages?.filter((obj) => obj.Channel !== ""));
        filterCoverageTable(
          coverageResponse?.coverages?.filter((obj) => obj.Channel !== ""),
          newBenefitType
        );
        setIsLoadingCoverage(false);
      }
    }
  };

  const coverageSearch = async (newBenefitType) => {
    const benefitTypeId = newBenefitType === "Pharmacy" ? 2 : 1;
    let rawGridColumns = gridColumnsBothBenefits.filter((p) => p.benefitTypeId === benefitTypeId);
    let filterGridColumns = rawGridColumns;
    if (filterValue.length > 0) {
      filterGridColumns = getFilterGridColumns(benefitTypeId, filterGridColumns);
    }
    setDynamicColumns(filterGridColumns);
    let searchIndication = selectedIndication != null ? selectedIndication.toString() : indicationid;
    const config = appliedConfiguration;
    if (!config) {
      console.log("Config not found for benefit type and indication");
    } else if (config.indicationId.toString() != searchIndication) {
      console.log("Config not found for indication");
    }
    let requestZipCode = null;
    let stateId = null;
    let npi = null;
    if (searchNPI.length === 10 && !isNaN(searchNPI)) {
      npi = searchNPI;
      setSearchTermDisplay(searchNPI);
    } else if (searchTerm.length > 0 && !isNaN(searchTerm[0])) {
      requestZipCode = searchTerm;
      setSearchTermDisplay(searchTerm);
    } else {
      const searchState =
        config.states.find((s) => s.name.toLowerCase() === searchTerm?.toLowerCase()) ||
        config.states.find((s) => s.name.toLowerCase() === searchTermDisplay?.toLowerCase());
      stateId = searchState?.stateId;
      setStateId(stateId);
      setSearchTermDisplay(searchState?.name);
    }
    const liveTypeId = newBenefitType === "Pharmacy" ? 4 : 1;

    if (!requestZipCode && !stateId && !npi) {
      //if a zip code was passed in to the component default to that when searching if nothing else is selected.
      requestZipCode = zipCode;
    }

    const coverageRequest = {
      ProjectId: config.projectId,
      ZipCode: requestZipCode,
      StateId: stateId,
      NPI: npi,
      ProductId: selectedProduct === null ? products[0].ProductId : selectedProduct.ProductId,
      LiveTypeId: liveTypeId,
      IndicationId: config.indicationId,
      resultName:
        newBenefitType === "Pharmacy"
          ? config.coverageFinderConfiguration.pharmacyResultName
          : config.coverageFinderConfiguration.resultName,
    };

    let rawCoverageData = await CoverageService.getInstance().getCoverage(coverageRequest);
    rawCoverageData.coverages = addPrescriberNoteFlagToCoverageRecord(
      rawCoverageData.coverages,
      rawGridColumns,
      newBenefitType
    );
    return rawCoverageData;
  };
  const handleSearchTerm=(value)=>{
    setSearchTerm(value);
    setDisableBenefitType(true);
    //setShowResults(false);
  };
  const handleIndication=(value)=>{
    setSelectedIndication(value);
    setDisableBenefitType(true);
  }
  const handleCoverageSearch = async () => {
    setResultMessage("");
    setIsLoadingCoverage(true);
    setShowResults(false);
    setOrderBy("");
    const coverageResponse = await coverageSearch(selectedBenefitType);
    if (coverageResponse != null && coverageResponse.coverages != null) {
      setDisableBenefitType(false);
      const showAll = ["Show All"];
      let allChannels;
      if (coverageResponse.projectChannels && coverageResponse.projectChannels.length > 0) {
        allChannels = showAll.concat(coverageResponse.projectChannels?.map((c) => c.channelName));
      } else {
        allChannels = showAll.concat(coverageResponse.channels);
      }
      setChannelFilter("Show All");
      setPlanNameFilter("");
      setChannels(allChannels);
      if (settings?.findIndex((setting) => setting.settingId === 82 && setting.isEnabled === true) > -1) {
        setChannels([]);
      }
      setRows(coverageResponse.coverages.filter((obj) => obj.Channel !== ""));
      setFilteredRows(coverageResponse.coverages.filter((obj) => obj.Channel !== ""));
      setDynamicStatement(coverageResponse.statement);

      if (coverageResponse.zipCode) {
        setSearchTermDisplay(coverageResponse.zipCode);
      } else if (coverageResponse.stateId) {
        setStateId(coverageResponse.stateId);
        var stateName = appliedConfiguration.states.find((s) => s.stateId === coverageResponse.stateId)?.name;
        setSearchTermDisplay(stateName);
      }

      if (coverageTableContainerRef?.current) {
        coverageTableContainerRef.current.scrollTo(0, 0);
      }

      setShowResults(true);
      setShowBenefitType(benefitTypes?.length > 1 ?? false);
      setIsLoadingCoverage(false);
    } else {
      setIsLoadingCoverage(false);
      setShowBenefitType(false);
      if (searchTerm || searchTermDisplay || searchNPI) {
        setResultMessage("No results found");
      }
    }
  };

  const handleSelectedProduct = (value) => {
    setSelectedProduct(value);
    onChangeProduct(value);
  };

  const onChangeProduct = async (product) => {
    setSelectedProduct(product);
    const requestBody = {
      ProductId: product.ProductId,
    };
    const configResponse = await ConfigurationService.getInstance().getConfiguration(requestBody);
    applyConfiguration(configResponse);
    setShowBenefitType(false);
  };

  function addPrescriberNoteFlagToCoverageRecord(coverageData, columns, selectedBenefitType) {
    coverageData?.forEach((currentRow) => {
      if (!Object.prototype.hasOwnProperty.call(currentRow, "IsPrescriberNotePresent")) {
        const isPrescriberNotePresent = PrescriberNoteService.IsPrescriberNotePresent(
          currentRow,
          columns,
          selectedBenefitType
        );
        currentRow.IsPrescriberNotePresent = isPrescriberNotePresent;
      }
    });
    return coverageData;
  }

  return (
    <>
      <link type="text/css" rel="stylesheet" href={stylePath} />
      {isLoadingPage && (
        <div className="norstella-cf-overlay">
          <Grid item className="norstella-cf-loaderTable">
            <CircularProgress />
          </Grid>
        </div>
      )}
      {!isLoadingPage && (
        <Box
          id="coverageFinderBox"
          className="norstella-cf-coverageFinderBox"
          sx={{ flexGrow: 1 }}
          part="cfCoverageFinderBox"
        >
          <Grid container spacing={2} direction={"column"} alignItems={"left"} className="norstella-cf-Grid">
            <Grid item className="norstella-cf-GridItemHeader">
              {/* Header */}
              <DynamicHtml
                input={header != null ? header : ""}
                className="norstella-cf-header"
              />
            </Grid>

            {!matchesMobileWidth && showMap && (
              <Grid item part="cfStateMap" className="norstella-cf-GridItemStateMap">
                <StateMap
                  states={appliedConfiguration.states}
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  setShowResults={handleShowResults}
                />
              </Grid>
            )}

            <Grid item className="norstella-cf-GridItemSearch">
              <div className="norstella-cf-div-row">
                <div className="norstella-cf-divSearchCoverage">
                  <SearchCoverage
                    clientProductConfigurations={clientProductConfigurations}
                    setShowResults={handleShowResults}
                    onChangeBenefitType={handleChangeBenefitType}
                    handleFilterChange={handleFilterChange}
                    handleProductChange={handleSelectedProduct}
                    onChangeNPI={setNPI}
                    onChangeSearchTerm={handleSearchTerm}
                    onChangeIndication={handleIndication}
                    handleCoverageSearch={handleCoverageSearch}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    benefitTypes={benefitTypes}
                    setResultMessage={setResultMessage}
                    selectedBenefitType={selectedBenefitType}
                    selectedProduct={selectedProduct}
                    products={products}
                    zipCode={zipCode}
                  />
                </div>
                {!matchesMobileWidth && benefitTypes?.length > 1 && showBenefitType && (
                  <div className="norstella-cf-divSearchCoverage">
                    <BenefitTypeButton
                      selectedBenefitType={selectedBenefitType}
                      benefitTypes={benefitTypes}
                      onChangeBenefitType={handleChangeBenefitType}
                      searchNPI={searchNPI}
                      disableBenefitType={disableBenefitType}
                    />
                  </div>
                )}
              </div>
              {resultMessage && resultMessage.trim() !== "" && (
                <div className="norstella-cf-resultmessage" part="cfResultmessage">
                  {resultMessage}
                </div>
              )}
            </Grid>

            {isLoadingCoverage && (
              <div className="norstella-cf-overlay">
                <Grid item className="norstella-cf-loaderTable">
                  <CircularProgress />
                </Grid>
              </div>
            )}
            {matchesMobileWidth && benefitTypes?.length > 1 && showBenefitType && (
              <Grid item className="norstella-cf-GridItemBenefitTypeButton">
                <BenefitTypeButton
                  selectedBenefitType={selectedBenefitType}
                  benefitTypes={benefitTypes}
                  onChangeBenefitType={handleChangeBenefitType}
                  searchNPI={searchNPI}
                  disableBenefitType={disableBenefitType}
                />
              </Grid>
            )}
            {showResults && (
              <>
                {subHeader && subHeader.trim() !== "" && (
                  <Grid item className="norstella-cf-GridItemSubHeader">
                    {/* SubHeader */}
                    <DynamicHtml
                      input={subHeader != null ? subHeader : ""}
                      className="norstella-cf-headerSubHeader"
                    />
                  </Grid>
                )}
                <Grid item className="norstella-cf-GridItemStatement">
                  {/* Dynamic Statement */}
                  <Statement dynamicStatement={dynamicStatement} />
                </Grid>
                <div id="divCoverageFilterAndTable" className="norstella-cf-coverageFilterAndTable">
                  <Grid item className="norstella-cf-GridItemFilterCoverage">
                    <FilterCoverage
                      channels={channels}
                      planSearch={setPlanNameFilter}
                      channelFilter={setChannelFilter}
                    />
                  </Grid>
                  <Grid item id="displayCoverageLayoutGridItem" className="norstella-cf-displayCoverageLayoutGridItem">
                    {!isLoadingCoverage && (
                      <DisplayCoverage
                        dynamicColumns={dynamicColumns}
                        tableRows={visibleRows}
                        orderBy={orderBy}
                        order={order}
                        onRequestSort={handleRequestSort}
                        ref={coverageTableContainerRef}
                        stateId={stateId}
                        noDataWarning={noDataWarning}
                        showDataSource={showDataSource}
                      />
                    )}
                  </Grid>
                </div>
                <Grid item className="norstella-cf-GridItemFooter">
                  <Footer customFooter={customFooter} />
                </Grid>
              </>
            )}
            {debugMode && (
              <Grid item className="norstella-cf-GridItemDebugMessage">
                <DebugMessage />
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default HCPCoverageFinder;
