import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import useMediaQuery from "@mui/material/useMediaQuery";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const FilterComponent = ({ Filters, OnFilterChange, allowIndicationSelection, isFilterSame }) => {
  // Determine if multiple selection is enabled based on Filters.isMultiple
  let isMultiple = Filters?.filterType === 2 ? true : false;
  const [selectedArrayValues, setSelectedArrayValues] = useState([]);
  const [selectedValues, setSelectedValues] = useState(null);
  const matchesMobileWidth = useMediaQuery("(max-width:1024px)");
  const autocompleteStyles = {
    width: allowIndicationSelection ? "200px" : "300px",
  };
  const handleChange = (event, value) => {
    isMultiple ? setSelectedArrayValues(value || []) : setSelectedValues(value);
    OnFilterChange(value,false);
  };
  useEffect(() => {
    if (!isFilterSame) {
      isMultiple ? setSelectedArrayValues([]) : setSelectedValues(null);
    } else {
      let previouslySelectedValue = isMultiple ? selectedArrayValues : selectedValues;
      if (previouslySelectedValue) {
        if (isMultiple) {
          const initialSelected = Filters.filterCriteria.filter((criteria) =>
            previouslySelectedValue.some((selected) => selected.filterCriteria === criteria.filterCriteria)
          );
          setSelectedArrayValues(initialSelected);
          OnFilterChange(initialSelected,isFilterSame);
        } else {
          const initialSelected = Filters.filterCriteria.find(
            (criteria) => criteria.filterCriteria === previouslySelectedValue.filterCriteria
          );
          setSelectedValues(initialSelected || null);
          OnFilterChange(initialSelected,isFilterSame);
        }
      }
    }
  }, [Filters, isMultiple]);

  return (
    <>
      {isMultiple !== undefined && (
        <FormControl
          sx={{ marginLeft: !matchesMobileWidth ? "20px" : "none", minWidth: "180px" }}
          fullWidth
          variant="outlined"
        >
          <Autocomplete
            style={!matchesMobileWidth ? autocompleteStyles : {}}
            sx={{ width: "100%" }}
            limitTags={2}
            disablePortal
            multiple={isMultiple}
            selectOnFocus
            handleHomeEndKeys
            disableClearable
            id="selectFilter"
            options={Filters.filterCriteria}
            value={isMultiple ? selectedArrayValues : selectedValues}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                {...params}
                className="norstella-cf-txtFilterSelect"
                placeholder={
                  (isMultiple && selectedArrayValues?.length) || (!isMultiple && selectedValues != null)
                    ? ""
                    : Filters.placeholder
                }
              />
            )}
            ChipProps={{
              deleteIcon: <span style={{ fontSize: "15px" }}>×</span>, // Reduce cancel icon size
              style: { height: "15px", margin: "none", fontSize: "0.8rem" }, // Customize chip height
            }}
            getOptionLabel={(option) => option.filterCriteria}
            part="cfFilterSelect"
            className="norstella-cf-FilterSelect"
          />
        </FormControl>
      )}
    </>
  );
};

export default FilterComponent;
