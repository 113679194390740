import React, { useState} from "react";
import { Autocomplete, TextField } from "@mui/material";

const ProductComponent = ({ products, OnProductChange }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleSelectedProduct = (event, value) => {
    setSelectedProduct(value);
    OnProductChange(value);
  };
  
  return (
    <>
      {products?.length > 1 && (
        <Autocomplete
          className="norstella-cf-ProductText"
          disablePortal
          selectOnFocus
          handleHomeEndKeys
          disableClearable
          id="productSelect"
          options={products}
          value={selectedProduct}
          onChange={handleSelectedProduct}
          renderInput={(params) => <TextField {...params} placeholder={selectedProduct ? "" : "Select Product"} />}
          getOptionLabel={(option) => option.Name}
          part="cfProductSelect" />
      )}
    </>
  );
};

export default ProductComponent;
