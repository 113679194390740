export default class StyleService {
  static classInstance = null;
  static getInstance() {
    if (StyleService.classInstance === null) {
      StyleService.classInstance = new StyleService();
    }
    return this.classInstance;
  }

  loadCustomFonts(clientId, cdnUrl, productId) {
    const font = document.createElement("link");
    font.href = `${cdnUrl}css/${clientId}_${productId}_fonts.css`;
    font.rel = "stylesheet";
    font.id = `${productId}_fonts`;

    let cfElement = document.getElementById("coverageFinder");
    if (!cfElement) {
      let cfElementArray = document.getElementsByTagName("coverage-finder");
      if (cfElementArray && cfElementArray.length) {
        cfElement = cfElementArray[0];
      } else {
        cfElement = document.head;
      }
    }

    if (!document.getElementById(font.id)) {
      cfElement.appendChild(font);
    }
  }
}
